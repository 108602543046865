import { Input } from 'components/ui/input';
import { Slider } from 'components/ui/slider';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Label } from 'components/ui/label';
import { REGEX, IMAGE_TYPE, SIGN_OFF_OPTIONS } from 'common/constants';
import { get, isString } from 'lodash';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { v4 as uuid } from 'uuid';
import React, { useEffect, useRef, useState } from 'react';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { ClearableInput } from 'components/Inputs';
import { fileUpload, getLiveUrl, resizeImage } from 'common/utils';
import { useGetCtaBannerSignedUrl } from 'hooks/cta';
import ColorPicker from 'components/ColorPicker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { SOCIAL_BANNER_LINKS } from 'modules/CallToAction/CallToActionControl/CallToActionControl';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { cn, isValidUrl } from 'lib/utils';
import CtaBannerDialog from 'modules/CallToAction/CallToActionControl/components/CtaBannerDialog';
import { Checkbox } from 'components/ui/checkbox';

const WorkspaceTemplateCallToAction = ({
  workspaceTemplate,
  handleSetWorkspaceTemplate,
}) => {
  const [showCustomSignOffInput, setShowCustomSignOffInput] = useState(false);
  const [getCtaBannerSignUrl] = useGetCtaBannerSignedUrl();
  const [showLogoUrlInput, setShowLogoUrlInput] = useState(false);
  const ctaBannerDialogRef = useRef();

  const ctaFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaTitle', 'ctaUrl'].includes(field.name),
  );
  const ctaBannerImgFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaBannerImg'].includes(field.name),
  );
  const ctaBannerUrlFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaBannerUrl'].includes(field.name),
  );
  const signOffFields = workspaceTemplate?.fields?.filter((field) =>
    ['signOff'].includes(field.name),
  );
  const socialBannersFields = workspaceTemplate?.fields?.filter((field) =>
    ['socialBanners'].includes(field.name),
  );

  const ctaFieldsSchema = ctaFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerImageSchema = ctaBannerImgFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerUrlSchema = ctaBannerUrlFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const signOffFieldsSchema = signOffFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const socialBannersSchema = socialBannersFields?.reduce((acc, field) => {
    if (field.name === 'socialBanners') {
      acc[field.name] = z
        .array(
          z.object({
            platform: z.string(),
            link:
              field.value !== null
                ? z.string().optional().or(z.literal(''))
                : z.string().nullable(),
            imgUrl: z.string().optional().or(z.literal('')),
          }),
        )
        .optional();
    }
    return acc;
  }, {});

  const schema = z.object({
    ...(ctaFieldsSchema ?? {}),
    ...(ctaBannerImageSchema ?? {}),
    ...(ctaBannerUrlSchema ?? {}),
    ...(signOffFieldsSchema ?? {}),
    ...(socialBannersSchema ?? {}),
  });

  const defaultValues = [
    ...ctaFields,
    ...ctaBannerImgFields,
    ...ctaBannerUrlFields,
    ...signOffFields,
    ...socialBannersFields,
  ]?.reduce((acc, field) => {
    acc[field.name] = field.value || '';
    return acc;
  }, {});

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...defaultValues,
      signOffColor: workspaceTemplate?.design?.styles?.signOff?.style?.color, // Default value for signOffColor
    },
  });

  const watch = form.watch();

  const [errors, setErrors] = useState({});

  const { styles } = workspaceTemplate?.design || {};
  const { cta, ctaBanner, signOff, socialBanners } = styles || {};

  const ctaBannerWidth = isString(ctaBanner?.style?.width)
    ? 0
    : ctaBanner?.style?.width || 250;

  const signOffFontSize = isString(signOff?.style?.fontSize)
    ? 0
    : signOff?.style?.fontSize || 25;

  const signOffColor =
    signOff?.style?.color || workspaceTemplate?.design?.primaryBrandColor;

  const socialBannersSize = isString(socialBanners?.style?.width)
    ? 0
    : socialBanners?.style?.width || 120;

  const ctabannerborderRadius = ctaBanner?.style?.ctaborderRadius || 0;
  const ctabuttontype = cta?.style?.type || 'colored';

  const validateWebsite = (website) => REGEX.WEB_URL.test(website);

  const handleDesignStyleChange = (key, values) => {
    const design = get(workspaceTemplate, 'design', {});
    const designStyles = get(design, 'styles', {});
    const data = {
      ...workspaceTemplate,
      design: {
        ...workspaceTemplate?.design,
        styles: {
          ...designStyles,
          [key]: {
            ...values,
          },
        },
      },
    };
    handleSetWorkspaceTemplate(data);
  };

  const generateSignUrl = async (variables) => {
    const res = await getCtaBannerSignUrl({
      variables: {
        data: {
          ...variables,
        },
      },
    });
    return res?.data?.getCtaBannerUploadSignedUrl;
  };

  const handleFileUpload = async (files, field) => {
    const file = files?.[0];
    if (file) {
      const isGif = file.type === IMAGE_TYPE.gif;
      let uploadFile = file;
      if (!isGif) {
        uploadFile = await resizeImage(file, 200);
      }
      const { name } = uploadFile || {};
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const filename = name.split('.').slice(0, -1).join('.');
      const newFilename = `${filename}.${ext}`;
      const res = await generateSignUrl({
        fileName: newFilename,
        id: workspaceTemplate?.id ?? uuid(),
        type: 'template',
      });
      if (res) {
        const { signedUrl, key } = res;
        try {
          await fileUpload(signedUrl, file);
          const liveUrl = getLiveUrl(key);
          form.setValue(field.name, liveUrl);
          form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
        } catch (error) {
          return false;
        }
      }
    }
  };

  const handleFormChange = () => {
    const values = form.getValues();
    const updatedArray = [...(workspaceTemplate?.fields ?? [])]?.map((item) => {
      if (values[item.name] !== undefined) {
        return {
          ...item,
          value: values[item.name],
        };
      }
      return item;
    });

    if (
      JSON.stringify(updatedArray) !== JSON.stringify(workspaceTemplate.fields)
    ) {
      handleSetWorkspaceTemplate({
        ...workspaceTemplate,
        fields: updatedArray,
      });
    }
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, workspaceTemplate]);

  const handleSocialBannerChange = (platform, value, imgUrls) => {
    const socialBanners = form.getValues('socialBanners') || [];
    const index = socialBanners.findIndex(
      (banner) => banner.platform === platform,
    );

    const imgUrl = socialBanners?.[index]?.imgUrl ?? imgUrls[0]?.url;

    if (value) {
      if (index !== -1) {
        socialBanners[index].link = value;
      } else {
        socialBanners.push({ platform, link: value, imgUrl });
      }
    } else if (index !== -1) {
      socialBanners.splice(index, 1);
    }

    form.setValue('socialBanners', socialBanners);
  };

  const handleImageVariantChange = (platform, selectedUrl) => {
    const socialBanners = form.getValues('socialBanners') || [];
    const index = socialBanners.findIndex(
      (banner) => banner.platform === platform,
    );
    const existingBanner = socialBanners?.[index] ?? null;
    const bannerLink = existingBanner ? existingBanner.link : '';
    if (!bannerLink) return;
    if (index !== -1) {
      socialBanners[index].imgUrl = selectedUrl;
    } else {
      socialBanners.push({ platform, imgUrl: selectedUrl });
    }

    form.setValue('socialBanners', socialBanners);
  };

  const isCustomText =
    watch?.signOff &&
    !SIGN_OFF_OPTIONS?.find((op) => op.value === watch?.signOff);

  const accordionData = [
    {
      value: 'item-1',
      title: 'Call to action Button',
      content: (
        <>
          <div>
            {ctaFields?.map((field) => (
              <FormField
                key={field.name}
                control={form.control}
                name={field.name}
                render={({ field: formField }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className="max-w-full mb-2"
                        placeholder={field.label}
                        {...formField}
                        type={field.type}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
          </div>
          <div>
            {watch?.ctaUrl && (
              <div className="rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>CTA Button Style</span>
                  </div>
                  <RadioGroup
                    value={ctabuttontype}
                    onValueChange={(value) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          type: value,
                        },
                      })
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="colored"
                        id="colored-ctaButtonStyle"
                      />
                      <Label
                        htmlFor="colored-ctaButtonStyle"
                        className="px-4 py-1 rounded-full bg-primary text-white-0"
                      >
                        Colored
                      </Label>
                      <RadioGroupItem
                        value="bordered"
                        id="bordered-ctaButtonStyle"
                      />
                      <Label
                        htmlFor="bordered-ctaButtonStyle"
                        className="px-4 py-1 rounded-full bg-transparent border-1 border-solid border-primary"
                      >
                        Bordered
                      </Label>
                      <RadioGroupItem value="text" id="text-ctaButtonStyle" />
                      <Label
                        htmlFor="text-ctaButtonStyle"
                        className="px-4 py-1 rounded-full"
                      >
                        Text
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            )}
          </div>
          <div>
            {watch?.ctaUrl && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Width</span>
                    <span>{parseFloat(cta?.style?.width)}px</span>
                  </div>
                  <Slider
                    min={50}
                    max={400}
                    value={[parseFloat(cta?.style?.width)]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          width: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {watch?.ctaUrl && ctabuttontype !== 'text' && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Border Radius</span>
                    <span>{cta?.style?.borderRadius}px</span>
                  </div>
                  <Slider
                    min={0}
                    max={15}
                    value={[cta?.style?.borderRadius]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          borderRadius: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      value: 'item-2',
      title: 'Call to action Banner',
      content: (
        <>
          <CtaBannerDialog
            ref={ctaBannerDialogRef}
            signature={workspaceTemplate}
            ctaBannerImgFields={ctaBannerImgFields}
            form={form}
            watch={watch}
          />
          <div>
            {watch.ctaBannerImg ? (
              <ImageUploadPreview
                className="rounded-lg mb-4"
                previewImageClassName="max-w-[80%]"
                onChange={() => {
                  form.setValue('ctaBannerImg', '');
                }}
                imageUrl={watch.ctaBannerImg}
              />
            ) : (
              <div
                className="relative rounded-lg h-[100px] overflow-hidden text-center flex items-center justify-center text-[16px] border-[1px] border-dashed border-gray-0 hover:border-primary hover:bg-transparent bg-transparent cursor-pointer"
                onClick={() => ctaBannerDialogRef.current?.open()}
              >
                Select or upload your CTA Banner
              </div>
            )}
            <div className="flex items-center mt-4">
              <Checkbox
                checked={showLogoUrlInput}
                onCheckedChange={setShowLogoUrlInput}
                className="mb-2 data-[state=unchecked]:bg-white-0 data-[state=unchecked]:text-primary-foreground"
              />
              <span className="ml-2">Host your image</span>
            </div>
            {showLogoUrlInput && (
              <div className="mt-2">
                <FormField
                  name="ctaBannerImg"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <ClearableInput
                          className="max-w-full mb-2"
                          placeholder="Enter hosted image url"
                          {...field}
                          type="text"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          allowClear
                          onChange={(e) => {
                            const newUrl = e.target.value;
                            field.onChange(newUrl);
                            form.trigger('ctaBannerImg');
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            {watch?.ctaBannerImg &&
              ctaBannerUrlFields?.map((field) => (
                <FormField
                  key={field.name}
                  control={form.control}
                  name={field.name}
                  render={({ field: formField }) => (
                    <FormItem>
                      <FormControl>
                        <ClearableInput
                          className="max-w-full mb-2"
                          placeholder={field.label}
                          {...formField}
                          type={field.type}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
          </div>
          <div>
            {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Width</span>
                    <span>{ctaBannerWidth}px</span>
                  </div>
                  <Slider
                    min={50}
                    max={600}
                    value={[ctaBannerWidth]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('ctaBanner', {
                        style: {
                          ...cta?.style,
                          width: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Border Radius</span>
                    <span>{ctabannerborderRadius}px</span>
                  </div>
                  <Slider
                    min={0}
                    max={15}
                    value={[ctabannerborderRadius]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('ctaBanner', {
                        style: {
                          ...ctaBanner?.style,
                          ctaborderRadius: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      value: 'item-3',
      title: 'Social Banner',
      content: (
        <div>
          {SOCIAL_BANNER_LINKS.map(({ platform, imgUrls, link }) => {
            const socialBanners = form.getValues('socialBanners') || [];
            const existingBanner = socialBanners.find(
              (banner) => banner.platform === platform,
            );
            const bannerLink = existingBanner ? existingBanner.link : link;
            const selectedImage = existingBanner?.imgUrl || imgUrls[0].url;
            const showError = bannerLink && !isValidUrl(bannerLink);

            return (
              <div key={platform} className="mb-4">
                <div className="grid grid-cols-[auto_1fr] gap-2">
                  {/* Dropdown to select image variant */}
                  <Select
                    value={selectedImage}
                    onValueChange={(val) =>
                      handleImageVariantChange(platform, val)
                    }
                    className="w-[auto] p-0"
                  >
                    <SelectTrigger
                      className="w-[auto] h-[auto] cursor-pointer bg-transparent border-0 p-0"
                      hideDropdownArrow
                    >
                      <SelectValue placeholder="Select Image" />
                    </SelectTrigger>
                    <SelectContent>
                      {imgUrls.map((img) => (
                        <SelectItem value={img.url} key={img.variant}>
                          <img
                            src={img.url}
                            alt={`${platform} variant ${img.variant}`}
                            className="h-8 w-auto"
                          />
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <div>
                    <ClearableInput
                      placeholder={`Enter link for ${platform}`}
                      value={bannerLink}
                      onChange={(e) =>
                        handleSocialBannerChange(
                          platform,
                          e.target.value,
                          imgUrls,
                        )
                      }
                      type="text"
                    />
                    {showError && (
                      <FormMessage className="m-0">
                        URL must start with https://{' '}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {form.getValues('socialBanners')?.some((item) => item.link) && (
            <div>
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Width</span>
                    <span>{socialBannersSize}px</span>
                  </div>
                  <Slider
                    min={50}
                    max={250}
                    value={[socialBannersSize]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('socialBanners', {
                        style: {
                          ...socialBanners?.style,
                          width: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      value: 'item-4',
      title: 'Sign Off',
      content: (
        <div className="mb-4 mt-6 w-full">
          {/* <div>
                <h2>Sign Off</h2>
              </div> */}
          <div
            className={cn(
              'grid gap-4',
              isCustomText || watch?.signOff
                ? ' grid-cols-[1fr_auto] '
                : 'grid-cols-[1fr]',
            )}
          >
            <FormField
              name="signOff"
              control={form.control}
              render={({ field }) => (
                <>
                  <div>
                    <FormItem className="mb-0">
                      <FormControl>
                        <Select
                          onOpenChange={(open) => {
                            if (open) {
                              setShowCustomSignOffInput(false);
                            }
                          }}
                          value={
                            isCustomText || showCustomSignOffInput
                              ? 'Custom Text'
                              : field.value
                          }
                          {...(!showCustomSignOffInput
                            ? {
                                onValueChange: (val) => {
                                  // Set value to empty string if "None" is selected
                                  if (val === 'Custom Text') {
                                    setShowCustomSignOffInput(true);
                                    form.setValue('signOff', '');
                                    return;
                                  }
                                  if (val === null) {
                                    field.onChange('');
                                  } else {
                                    field.onChange(val);
                                  }
                                },
                              }
                            : {})}
                          className="bg-white-0 w-full"
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select Sign Off" />
                          </SelectTrigger>
                          <SelectContent side="top">
                            {SIGN_OFF_OPTIONS?.map((item) => (
                              <SelectItem
                                value={item.value}
                                className="mb-0 w-full"
                                key={item.label}
                              >
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                </>
              )}
            />

            {watch?.signOff && (
              <ColorPicker
                defaultValue={signOffColor}
                value={signOffColor}
                colorPickerWrapperClassName="justify-end mb-2"
                onChange={(value) => {
                  handleDesignStyleChange('signOff', {
                    style: {
                      ...signOff?.style,
                      color: `#${value.toHex().slice(0, 6)}`,
                    },
                  });
                }}
              />
            )}
          </div>

          <div>
            {(showCustomSignOffInput || isCustomText) && (
              <div className="mt-4">
                <ClearableInput
                  placeholder="Enter custom sign off"
                  value={watch?.signOff}
                  onChange={(e) => form.setValue('signOff', e.target.value)} // Set custom text value
                />
              </div>
            )}
          </div>

          <div>
            {watch?.signOff && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Size</span>
                    <span>{signOffFontSize}px</span>
                  </div>
                  <Slider
                    min={12}
                    max={40}
                    value={[signOffFontSize]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('signOff', {
                        style: {
                          ...signOff?.style,
                          fontSize: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleKeyDown = (e) => {
    // Prevent the form from submitting when Enter is pressed
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="p-[16px] pl-4 pr-4">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(() => {})}
          onKeyDown={handleKeyDown}
          className="w-full"
        >
          <Accordion type="single" collapsible defaultValue="item-1">
            {accordionData.map((item) => (
              <AccordionItem key={item.value} value={item.value}>
                <AccordionTrigger className="bg-[transparent] border-1 border-solid shadow-none border-gray-0 rounded-[8px] hover:no-underline cursor-pointer outline-none focus-visible:outline-none">
                  <h1 className="font-primary text-h6 font-bold text-neutral-1000 m-0">
                    {item.title}
                  </h1>
                </AccordionTrigger>
                <AccordionContent>{item.content}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </form>
      </Form>
    </div>
  );
};

export default WorkspaceTemplateCallToAction;
