/* eslint-disable no-nested-ternary */
import React from 'react';
import WithImageConversion from 'common/helpers/htmlToImage';
import { get, omit } from 'lodash';
import { TEXT_IDS } from 'common/constants';
import { socialImages } from 'assets/social-images';

const PhoneTypeWrapper = ({
  type,
  styles,
  iconDimension,
  children,
  signature,
  showAwsIcons,
  customWord,
}) => {
  const { fontSize, lineHeight } = styles;
  const child = {
    letter: 'P.',
    word: customWord ? `${customWord}` : 'Company Phone.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      fontSize,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
    },
    word: {
      fontSize,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
    },
    rounded: { borderRadius: '50%', ...iconDimension },
    square: { borderRadius: '5px', ...iconDimension },
    none: { display: 'none' },
  };

  const content = ['letter', 'word'].includes(type) ? (
    <span
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </span>
  ) : (
    <p
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </p>
  );
  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="company-phone"
        fileName="company-phone"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function CompanyPhone({
  design,
  style = {},
  tdProps = {},
  signature = {},
  data = {},
  showAwsIcons = false,
  customWord,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = get(icon, 'type', 'rounded');
  // const size = get(icon, 'style.size', 16);
  const size = fontSize + 4;

  const textStyle = {
    fontSize,
    lineHeight:
      type === 'letter' || type === 'word' || type === 'none' ? lineHeight : '',
    color,
  };

  return (
    <td
      style={{
        ...style,
        borderSpacing: '0px',
        paddingBottom: style?.paddingBottom || '6px',
        paddingTop: style?.paddingTop || '0px',
        paddingRight: style?.paddingRight || '0px',
        paddingLeft: style?.paddingLeft || '0px',
      }}
      {...tdProps}
    >
      <span
        style={{
          verticalAlign: 'middle',
          display: 'inline-block',
        }}
      >
        <PhoneTypeWrapper
          signature={signature}
          showAwsIcons={showAwsIcons}
          type={type}
          iconDimension={{ width: size, height: size }}
          styles={{
            height: type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
            width: type === 'letter' || type === 'word' ? 'auto' : '16px',
            backgroundColor: design?.primaryBrandColor,
            fontSize,
            verticalAlign: 'middle',
          }}
          customWord={customWord}
        >
          <img
            src={socialImages?.['phone-circle']}
            alt="company-phone"
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </PhoneTypeWrapper>
      </span>
      {type !== 'none' && <>&nbsp;</>}
      <span
        id={TEXT_IDS.COMPANYPHONE}
        className="item-center justify-center"
        style={{
          ...textStyle,
          textAlign: 'left',
          textDecoration: 'none',
          verticalAlign: 'middle',
          // paddingLeft: type === 'none' ? '0px' : '4px',
          // display: 'inline-block',
          // wordWrap: 'break-word',
          // overflowWrap: 'break-word',
          // whiteSpace: 'normal',
          // maxWidth: '200px',
        }}
      >
        {data?.value}
      </span>
    </td>
  );
}

export default CompanyPhone;
