import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection } from 'common/utils';
import { get, isString, omit } from 'lodash';
import React from 'react';
import { FONT_FAMILY } from 'common/constants';
import { getCtaCustomStyle } from '../../utils/constant_style';

const CtaTypeWrapper = ({
  type,
  styles,
  children,
  signature,
  showAwsIcons,
}) => {
  const updatedStyle = {
    colored: {
      ...styles,
      backgroundColor: styles?.primaryBrandColor,
      color: 'white',
      padding: '6px 12px',

      justifyContent: styles.justifyContent || 'center',
    },
    bordered: {
      ...styles,
      backgroundColor: 'transparent',
      border: `1px solid ${styles?.primaryBrandColor}`,
      color: styles?.primaryBrandColor,
      padding: '6px 12px',

      justifyContent: styles.justifyContent || 'center',
      verticalAlign: 'center',
    },
    text: {
      ...styles,
      backgroundColor: 'transparent',
      color: styles?.primaryBrandColor,
      padding: '0px 0px',
      textDecoration: 'underline',
      fontWeight: 700,
      justifyContent: styles.justifyContent || 'left',
    },
  };

  const content = (
    <span
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        cursor: 'pointer',
        width: styles.width || 100,
        borderRadius: styles.borderRadius || 0,
      }}
    >
      {children}
    </span>
  );

  if (['colored', 'bordered'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="cta"
        fileName="cta"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function CallToAction({
  design,
  data,
  style = {},
  tdProps = {},
  signature = {},
  tableprops = {},
  showAwsIcons,
}) {
  const { styles } = get(signature, 'design', {});
  const {
    width = 100,
    borderRadius,
    type = 'colored',
  } = get(styles, 'cta.style', {});
  const { ctaTitle, ctaUrl } = data;

  const customStyle = {
    ...style,
    primaryBrandColor: design?.primaryBrandColor,
    width,
    borderRadius,
  };

  const link = ctaUrl?.utagEnabled
    ? ctaUrl?.shortLink || handleUrlRedirection(ctaUrl?.value)
    : handleUrlRedirection(ctaUrl?.value);

  const font = get(signature, 'design.font', FONT_FAMILY);
  const { fontSize } = get(styles, 'font.style', {});

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        fontSize,
        userSelect: 'none',
      }}
    >
      <tr>
        <td
          aria-label="cta"
          style={{
            ...style,
            paddingTop: '8px',
            paddingBottom: style?.paddingBottom || '16px',
          }}
          {...tdProps}
        >
          <a
            aria-label="link"
            style={{
              color: 'transparent',
              textDecoration: 'none',
              display: 'inline-block',
            }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <CtaTypeWrapper
              type={type}
              styles={customStyle}
              signature={signature}
              showAwsIcons={showAwsIcons}
            >
              {ctaTitle?.value}
            </CtaTypeWrapper>
          </a>
        </td>
      </tr>
    </table>
  );
}

export default CallToAction;
