/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtyNine({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const socialLink = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.value)].includes(field.value),
  );

  const allUrlsAreNull =
    !getFieldData(fields, 'companyLogo') &&
    !getFieldData(fields, 'companyName') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  const contentWidth = get(design, 'styles.content.style.width', 600);
  const backgroundImage =
    'url("https://static.dev.sendsig.com/asset/Template39_bg.png")'; // Static background image
  const backgroundSize = 'auto'; // Static background size
  const backgroundPosition = 'center'; // Static background position
  const backgroundRepeat = 'no-repeat'; // Static background repeat
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}

        <tr>
          <td
            aria-label="main-content"
            style={{
              paddingBottom: '2px',
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                backgroundImage,
                backgroundSize,
                backgroundPosition,
                backgroundRepeat,
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                    style={{
                      paddingBottom: '8px',
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          paddingRight: allUrlsAreNull ? '0px' : '30px',
                          verticalAlign: 'top',
                        }}
                      >
                        <table cellPadding="0" cellSpacing="0">
                          {getFieldData(fields, 'headshotUrl') && (
                            <tr align="left">
                              <HeadshotImage
                                data={getFieldData(fields, 'headshotUrl')}
                                design={design}
                                align="left"
                                style={{
                                  paddingBottom: '8px',
                                }}
                              />
                            </tr>
                          )}
                          {getFieldData(fields, 'name') && (
                            <tr>
                              {Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingBottom: '8.0pt',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'position') && (
                            <tr>
                              {Position({
                                signature,
                                data: getFieldData(fields, 'position'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingBottom: '8.0pt',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'department') && (
                            <tr>
                              {Department({
                                signature,
                                data: getFieldData(fields, 'department'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingBottom: '0px',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'phone') && (
                            <tr>
                              {Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                                style: {
                                  paddingBottom: '0px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'email') && (
                            <tr>
                              {Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                                style: {
                                  paddingBottom: '0px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                      {!allUrlsAreNull && (
                        <td
                          className={TEXT_IDS.SEPARATOR}
                          style={{
                            fontStyle: 'normal',
                            fontWeight: 0,
                            lineHeight: '100%',
                            borderLeft: `${separatorWidth}px ${separatorType} ${separatorColor}`,
                            paddingLeft: '15px',
                            verticalAlign: 'top',
                          }}
                        >
                          <table
                            cellPadding="0px"
                            cellSpacing="0"
                            border="0"
                            style={{
                              borderCollapse: 'collapse',
                            }}
                          >
                            {getFieldData(fields, 'companyLogo') && (
                              <tr>
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                  style: { paddingBottom: '8.0pt' },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyName') && (
                              <tr>
                                {CompanyName({
                                  signature,
                                  data: getFieldData(fields, 'companyName'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '8.0pt',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'website') && (
                              <tr>
                                {Website({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'website'),
                                  style: {
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyEmail') && (
                              <tr>
                                {CompanyEmail({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyEmail'),
                                  style: {
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyPhone') && (
                              <tr>
                                {CompanyPhone({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyPhone'),
                                  style: {
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyAddress') && (
                              <tr>
                                {CompanyAddress({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyAddress'),
                                  style: {
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      )}
                    </tr>
                  </table>
                </td>
              </tr>
              {socialFields?.length !== 0 && (
                <tr>
                  <td
                    height={8}
                    className={TEXT_IDS.SEPARATOR}
                    colSpan={2}
                    style={{
                      borderTop:
                        socialFields?.length !== 0
                          ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                          : '',
                      lineHeight: 0,
                      margin: 0,
                    }}
                  />
                </tr>
              )}

              {!!socialFields?.length && (
                <tr>
                  {SocialLinks({
                    signature,
                    showAwsIcons,
                    data: socialFields,
                    design,
                    style: {
                      paddingBottom: '0px',
                    },
                  })}
                </tr>
              )}
            </table>
          </td>
        </tr>
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleThirtyNine;
