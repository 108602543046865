/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-return-await */

import useGoogleWorkspaceUsers from 'hooks/google';
import { Buffer } from 'buffer';
import { fileUpload } from 'common/utils';
import { useGetGoogleImageUploadSignedUrl } from 'hooks/google-marketplace';
import { v4 as uuid } from 'uuid';

const useGetGoogleProfileImage = () => {
  const { fetchGoogleWorkspaceUserPhoto } = useGoogleWorkspaceUsers();
  const [getGoogleImageUploadSignedUrl] = useGetGoogleImageUploadSignedUrl();

  const fetchUserProfile = async (userKey, userId, token) => {
    try {
      const { base64Image, mimeType } = await fetchGoogleWorkspaceUserPhoto(
        token,
        userKey,
      );

      if (!base64Image) {
        return null;
      }

      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const id = uuid();
      const s3Key = `${userId}-${id}-${timestamp}.jpg`;

      const base64Data = base64Image.replace(/^data:image\/\w+;base64,/, '');

      // Convert base64 string to Buffer
      const imageBuffer = Buffer.from(base64Data, 'base64');

      const res = await getGoogleImageUploadSignedUrl({
        variables: {
          data: {
            fileName: s3Key,
          },
        },
      });
      if (res) {
        const { signedUrl, key } = res?.data?.getGoogleImageUploadSignedUrl;
        await fileUpload(signedUrl, imageBuffer);
        return `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
      }

      // Upload the image to S3
      // const res = await s3
      //   .putObject({
      //     Bucket:
      //       process.env.REACT_APP_ENV === 'production'
      //         ? 'send-sig'
      //         : 'send-sig-dev',
      //     Key: s3Key,
      //     Body: imageBuffer,
      //     ContentType: mimeType || 'image/jpeg',
      //     ACL: 'public-read',
      //   })
      //   .promise();

      // if (res) {
      //   return `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${s3Key}`;
      // }
    } catch (error) {
      console.log('error', error);
      return null;
    }

    return null;
  };

  return { fetchUserProfile };
};

export default useGetGoogleProfileImage;
