/* eslint-disable react/no-array-index-key */
import { handleUrlRedirection } from 'common/utils';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { FONT_FAMILY } from 'common/constants';

function SocialBanners({
  data,
  style = {},
  toProps = {},
  signature = {},
  design = {},
  alignment = 'horizontal',
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const width = get(design, 'styles.socialBanners.style.width', 100);
  const socialBannersborderRadius = get(
    design,
    'styles.socialBanners.style.socialBannersRadius',
    0,
  );

  const mainTrWidth = 600;
  const maxItemsPerRow = Math.floor(mainTrWidth / width);

  // Dynamically chunk the data for horizontal alignment
  const chunkedSocialBanners = useMemo(() => {
    if (alignment === 'horizontal') {
      const chunks = [];
      let currentChunk = [];
      data.value.forEach((item, index) => {
        currentChunk.push(item);
        if ((index + 1) % maxItemsPerRow === 0) {
          chunks.push(currentChunk);
          currentChunk = [];
        }
      });
      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
      }
      return chunks;
    }
    return [data.value];
  }, [data.value, alignment, maxItemsPerRow]);

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
        maxWidth: alignment === 'horizontal' ? `${mainTrWidth}px` : 'auto',
      }}
    >
      <tr>
        <td
          style={{
            ...style,
            textAlign: 'left',
            paddingBottom: style?.paddingBottom || '8px',
          }}
          {...toProps}
        >
          <table
            cellPadding="0"
            cellSpacing="0"
            style={{
              borderCollapse: 'collapse',
              fontSize: 'inherit',
              width: '100%',
            }}
          >
            <tbody>
              {alignment === 'horizontal'
                ? chunkedSocialBanners.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map(({ imgUrl, link, platform, shortLink }) => (
                        <td
                          key={platform}
                          style={{
                            paddingRight: '8px',
                            paddingBottom: '8px',
                            width: `${width}px`, // Ensure consistent width for individual banners
                          }}
                        >
                          <a
                            aria-label="link"
                            style={{
                              color: 'transparent',
                              textDecoration: 'none',
                            }}
                            href={
                              data?.utagEnabled && shortLink ? shortLink : link
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={imgUrl}
                              alt={platform}
                              width={width}
                              title="socialbanners"
                              style={{
                                border: '0px',
                                lineHeight: '100%',
                                outline: 'none',
                                textDecoration: 'none',
                                display: 'block',
                                borderRadius: `${socialBannersborderRadius}px`,
                              }}
                            />
                          </a>
                        </td>
                      ))}
                    </tr>
                  ))
                : data.value.map(({ imgUrl, link, platform, shortLink }) => (
                    <tr key={platform}>
                      <td
                        style={{
                          paddingBottom: '8px',
                          width: `${width}px`,
                        }}
                      >
                        <a
                          aria-label="link"
                          style={{
                            color: 'transparent',
                            textDecoration: 'none',
                          }}
                          href={
                            data?.utagEnabled && shortLink ? shortLink : link
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={imgUrl}
                            alt="brand-logo"
                            width={width}
                            title="socialbanners"
                            style={{
                              border: '0px',
                              lineHeight: '100%',
                              outline: 'none',
                              textDecoration: 'none',
                              display: 'block',
                              borderRadius: `${socialBannersborderRadius}px`,
                            }}
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  );
}

export default SocialBanners;
