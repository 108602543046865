/* eslint-disable no-nested-ternary */
import React from 'react';
import WithImageConversion from 'common/helpers/htmlToImage';
import { get, omit } from 'lodash';
import { TEXT_IDS } from 'common/constants';
import { socialImages } from 'assets/social-images';

const PhoneTypeWrapper = ({
  type,
  styles,
  iconDimension,
  children,
  signature,
  showAwsIcons,
  customWord,
}) => {
  const { fontSize, lineHeight } = styles;
  const child = {
    letter: 'P.',
    word: customWord ? `${customWord}` : 'Phone.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      fontSize,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
    },
    word: {
      fontSize,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
    },
    rounded: { borderRadius: '50%', ...iconDimension },
    square: { borderRadius: '5px', ...iconDimension },
    none: { display: 'none' },
  };

  const content = ['letter', 'word'].includes(type) ? (
    <span
      id={TEXT_IDS.PHONEWORD}
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </span>
  ) : (
    <p
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        margin: '0px',
        padding: '0px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="phone"
        fileName="phone"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function Phone({
  design,
  style = {},
  tdProps = {},
  signature = {},
  data = {},
  showAwsIcons = false,
  customWord,
  showAnchor = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = get(icon, 'type', 'rounded');
  // const size = get(icon, 'style.size', 16);
  const size = fontSize + 4;

  const textStyle = {
    fontSize,
    lineHeight:
      type === 'letter' || type === 'word' || type === 'none' ? lineHeight : '',
    color,
  };

  return (
    <td
      style={{
        ...style,
        paddingBottom: style?.paddingBottom || '6px',
        paddingTop: style?.paddingTop || '0px',
        paddingRight: style?.paddingRight || '0px',
        paddingLeft: style?.paddingLeft || '0px',
        textAlign: 'left',
        alignItems: 'center',
      }}
      valign="middle"
      {...tdProps}
    >
      <span
        style={{
          verticalAlign: 'middle',
          // width: '100%',
          display: 'inline-block',
        }}
      >
        <PhoneTypeWrapper
          signature={signature}
          showAwsIcons={showAwsIcons}
          type={type}
          iconDimension={{ height: size, width: size }}
          styles={{
            height: type === 'letter' || type === 'word' ? 'auto' : '16px',
            width: type === 'letter' || type === 'word' ? 'auto' : '16px',
            backgroundColor: design?.primaryBrandColor,
            fontSize,
            verticalAlign: 'middle',
          }}
          customWord={customWord}
        >
          <img
            src={socialImages?.['phone-circle']}
            alt="phone"
            style={{
              height: '100%',
              width: '100%',
            }}
            width="100%"
          />
        </PhoneTypeWrapper>
      </span>
      {type !== 'none' && <>&nbsp;</>}

      {!showAnchor && (
        <span
          id={TEXT_IDS.PHONE}
          className="item-center justify-center"
          style={{
            ...textStyle,
            textAlign: 'left',
            textDecoration: 'none',
            verticalAlign: 'middle',
          }}
          align="top"
        >
          {data?.value}
        </span>
      )}
      {showAnchor && (
        <a
          id={TEXT_IDS.PHONE}
          href={`tel:${data?.value}`}
          style={{
            ...textStyle,
            textAlign: 'left',
            textDecoration: 'none',
            verticalAlign: 'middle',
          }}
          target="_blank"
          rel="noreferrer"
        >
          {data?.value}
        </a>
      )}
    </td>
  );
}

export default Phone;
