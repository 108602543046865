/* eslint-disable arrow-body-style */
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';

const templateSchema = z.object({
  templateName: z
    .string()
    .min(1, 'Template name is required')
    .refine((value) => value.trim() !== '', 'Template name is required'), // Ensure no blank space
});

const RenameTemplate = ({ data, onClose, handleOnClick }) => {
  const form = useForm({
    resolver: zodResolver(templateSchema),
    defaultValues: {
      templateName: data?.name || '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const handleDataOnSubmit = (values) => {
    const { templateName } = values;
    handleOnClick({ name: templateName, templateId: data?.id });
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(handleDataOnSubmit)}>
        <DialogHeader>
          <DialogTitle>Rename Template</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <FormField
            control={control}
            name="templateName"
            render={({ field }) => (
              <FormItem className="flex-1 text-lg">
                <FormLabel>Template Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    id="templateName"
                    placeholder="Enter Template Name"
                    className="h-[20px]"
                  />
                </FormControl>
                <FormMessage>{errors.templateName?.message}</FormMessage>
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end gap-3 pt-4">
          <Button
            type="button"
            className="w-[max-content]"
            onClick={() => onClose()}
            variant="outline"
            size="sm"
          >
            Cancel
          </Button>
          <Button type="submit" size="sm">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default RenameTemplate;
