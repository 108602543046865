import { TEXT_IDS } from 'common/constants';
import { get } from 'lodash';
import React from 'react';

function Position({ data = {}, style = {}, tdProp = {}, signature = {} }) {
  const { font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  // const defaultTextColor = color;
  const value = data?.value?.trim();
  const textStyle = {
    fontSize,
    lineHeight,
    color,
  };
  return (
    <td
      id={TEXT_IDS.POSITION}
      style={{
        ...textStyle,
        ...style,
        paddingBottom: style?.paddingBottom || '2px',
      }}
      {...tdProp}
    >
      {value}
    </td>
  );
}
export default Position;
