/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleFourty({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  // const headshot = signature?.headshot;

  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone');

  const PhoneEmail =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  const contentWidth = get(design, 'styles.content.style.width', 600);

  const backgroundColor = design?.primaryBrandColor;

  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            aria-label="main-content"
            style={{
              borderCollapse: 'collapse',
              fontSize: 'inherit',
              paddingBottom: '2px',
            }}
          >
            <table cellPadding="0" cellSpacing="0" border="0">
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      {!section1 && (
                        <td
                          style={{
                            verticalAlign: 'top',
                            paddingRight: getFieldData(fields, 'headshotUrl')
                              ? '14px'
                              : '0px',
                          }}
                        >
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                  style: {
                                    paddingBottom: '8px',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      )}

                      <td
                        style={{
                          verticalAlign: 'top',
                        }}
                      >
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderCollapse: 'collapse',
                          }}
                        >
                          {!section2 && (
                            <tr>
                              <td
                                style={{
                                  paddingBottom: '6px',
                                }}
                              >
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    fontSize: 'inherit',
                                  }}
                                >
                                  <tr>
                                    <td>
                                      <table
                                        width="400px"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border="0"
                                        style={{
                                          fontSize: 'inherit',
                                          paddingBottom: '6px',
                                          backgroundColor,
                                          padding: '4px',
                                        }}
                                      >
                                        {getFieldData(fields, 'name') && (
                                          <tr>
                                            {Name({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'name',
                                              ),
                                              style: {
                                                fontWeight: 700,
                                                color: '#FFFFFF',
                                                fontFamily: 'inherit',
                                                paddingBottom: '2px',
                                              },
                                            })}
                                          </tr>
                                        )}
                                        {getFieldData(fields, 'position') && (
                                          <tr>
                                            {Position({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'position',
                                              ),
                                              style: {
                                                color: '#FFFFFF',
                                                fontFamily: 'inherit',
                                                paddingBottom: '2px',
                                              },
                                            })}
                                          </tr>
                                        )}
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0px"
                                        cellSpacing="0"
                                        border="0"
                                        style={{ paddingLeft: '4px' }}
                                      >
                                        {getFieldData(fields, 'department') && (
                                          <tr>
                                            {Department({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'department',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                                paddingBottom: '2px',
                                                paddingTop: '10px',
                                              },
                                            })}
                                          </tr>
                                        )}
                                        {getFieldData(
                                          fields,
                                          'companyName',
                                        ) && (
                                          <tr>
                                            {CompanyName({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'companyName',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                                paddingBottom: '2px',
                                              },
                                            })}
                                          </tr>
                                        )}
                                        {getFieldData(
                                          fields,
                                          'companyAddress',
                                        ) && (
                                          <tr>
                                            {CompanyAddress({
                                              signature,
                                              showAwsIcons,
                                              design,
                                              data: getFieldData(
                                                fields,
                                                'companyAddress',
                                              ),
                                            })}
                                          </tr>
                                        )}
                                        {getFieldData(fields, 'email') && (
                                          <tr>
                                            {Email({
                                              signature,
                                              showAwsIcons,
                                              design,
                                              data: getFieldData(
                                                fields,
                                                'email',
                                              ),
                                            })}
                                          </tr>
                                        )}
                                        {getFieldData(
                                          fields,
                                          'companyEmail',
                                        ) && (
                                          <tr>
                                            {CompanyEmail({
                                              signature,
                                              showAwsIcons,
                                              design,
                                              data: getFieldData(
                                                fields,
                                                'companyEmail',
                                              ),
                                            })}
                                          </tr>
                                        )}
                                        <tr>
                                          <td>
                                            <table
                                              cellPadding="0"
                                              cellSpacing="0"
                                              border="0"
                                              style={{
                                                fontSize: 'inherit',
                                              }}
                                            >
                                              {(getFieldData(fields, 'phone') ||
                                                getFieldData(
                                                  fields,
                                                  'companyPhone',
                                                )) && (
                                                <tr>
                                                  {getFieldData(
                                                    fields,
                                                    'phone',
                                                  ) &&
                                                    Phone({
                                                      signature,
                                                      showAwsIcons,
                                                      design,
                                                      data: getFieldData(
                                                        fields,
                                                        'phone',
                                                      ),
                                                      customWord: 'Ph:',
                                                      style: {
                                                        paddingRight: '8px',
                                                      },
                                                    })}

                                                  {getFieldData(
                                                    fields,
                                                    'companyPhone',
                                                  ) && (
                                                    <td
                                                      style={{
                                                        verticalAlign: 'top',
                                                        color: getFieldData(
                                                          fields,
                                                          'companyPhone',
                                                        )
                                                          ? signature?.design
                                                              ?.primaryBrandColor
                                                          : 'none',
                                                      }}
                                                    >
                                                      {' '}
                                                      |&nbsp;
                                                    </td>
                                                  )}
                                                  {getFieldData(
                                                    fields,
                                                    'companyPhone',
                                                  ) &&
                                                    CompanyPhone({
                                                      signature,
                                                      showAwsIcons,
                                                      design,
                                                      customWord: 'Cell:',
                                                      data: getFieldData(
                                                        fields,
                                                        'companyPhone',
                                                      ),
                                                    })}
                                                </tr>
                                              )}
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <table
                                              cellPadding="0px"
                                              cellSpacing="0"
                                              border="0"
                                              style={{
                                                paddingTop: '8px',
                                              }}
                                            >
                                              <tr>
                                                {getFieldData(
                                                  fields,
                                                  'companyLogo',
                                                ) && (
                                                  <CompanyLogo
                                                    data={getFieldData(
                                                      fields,
                                                      'companyLogo',
                                                    )}
                                                    design={design}
                                                    style={{
                                                      paddingBottom: '8px',
                                                      paddingRight: '8px',
                                                    }}
                                                  />
                                                )}
                                                <td>
                                                  <table
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                  >
                                                    {getFieldData(
                                                      fields,
                                                      'website',
                                                    ) && (
                                                      <tr>
                                                        {Website({
                                                          signature,
                                                          showAwsIcons,
                                                          design,
                                                          data: getFieldData(
                                                            fields,
                                                            'website',
                                                          ),
                                                          style: {
                                                            paddingBottom:
                                                              '8px',
                                                          },
                                                        })}
                                                      </tr>
                                                    )}
                                                    {!!socialFields?.length && (
                                                      <tr>
                                                        {SocialLinks({
                                                          signature,
                                                          showAwsIcons,
                                                          data: socialFields,
                                                          design,
                                                          paddingBottom: '6px',
                                                        })}
                                                      </tr>
                                                    )}
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleFourty;
