import Templates from 'modules/Templates';
import Teammates from 'modules/Teammates';
import Groups from 'modules/Groups';
import Assets from 'modules/Assets';
import AiHeadshot from 'modules/AiHeadshot';
import Account from 'modules/Account';
import CreatePassword from 'modules/Auth/CreatePassword';
import TeammateMySignature from 'modules/TeammateMySignature';
import GroupEdit from 'modules/GroupEdit';
import TeammateEdit from 'modules/TeammateEdit';
import MaintenancePage from 'components/MaintenancePage';
import RestrictedContentCard from 'components/RestrictedContentCard';
import WhiteLabel from 'modules/WhiteLabel';
import Integrations from 'modules/Integrations';
import OnboardingInfo from 'modules/OnboardingInfo';
import GoogleAuth from 'components/GoogleAuth';
import TemplateDialog from 'modules/Templates/components/Template/components/TemplateDialog';
import { FEATURE_KEYS, PLAN_ID_ARRAY, ROLES } from './common/constants';
import CallToAction from './modules/CallToAction';
import Design from './modules/Design';
import EmailSignature from './modules/EmailSignature';
import Information from './modules/Information';
import Main from './modules/Main';
import Profile from './modules/Profile';
import PrivateHeadshot from './modules/PrivateHeadshot/PrivateHeadshot';
import PublicHeadShot from './modules/PublicHeadshot';
import SubsriptionPage from './modules/SubscriptionPage/SubsriptionPage';
import BillingPage from './modules/Billing/BillingPage';
import Analytics from './modules/Analytics/Analytics';
import ForgetPassword from './modules/Auth/ForgetPassword';
import Login from './modules/Auth/Login';
import Logout from './modules/Auth/Logout';
import ResetPassword from './modules/Auth/ResetPassword';
import Signup from './modules/Auth/Signup';
import Headshot from './modules/Headshot';
import Onboarding from './modules/Onboarding/Onboarding';
import TemplatesPage from './components/TemplatesModel';
import PublicHeadshotEditor from './modules/PublicHeadshot/PublicHeadshotEditor';
import PrivateHeadshotEditor from './modules/PrivateHeadshot/PrivateHeadshotEditor';
import ProfileSetting from './modules/WorkspaceSetting/ProfileSetting';
import WorkspaceAnalytics from './modules/WorkspaceAnalytics';

export const publicRoutes = [
  {
    path: '/public-headshot',
    name: 'PUBLIC HEADSHOT',
    title: 'PUBLIC HEADSHOT',
    component: PublicHeadShot,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'public',
  },
  {
    path: '/google-auth',
    name: 'GOOGLE AUTH',
    title: 'GOOGLE AUTH',
    component: GoogleAuth,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'public',
  },
  {
    path: '/try-headshot',
    name: 'TRY HEADSHOT',
    title: 'TRY HEADSHOT',
    component: PublicHeadshotEditor,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'public',
  },
];

export const authRoutes = [
  {
    path: '/signup',
    name: 'SIGNUP',
    title: 'SIGNUP',
    component: Signup,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
  {
    path: '/login',
    name: 'LOGIN',
    title: 'LOGIN',
    component: Login,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'FORGET PASSWORD',
    title: 'FORGET PASSWORD',
    component: ForgetPassword,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
  {
    path: '/reset-password',
    name: 'RESET',
    title: 'RESET',
    component: ResetPassword,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
  {
    path: '/create-password',
    name: 'RESET',
    title: 'RESET',
    component: CreatePassword,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
  {
    path: '/authentication',
    name: 'AUTHENTICATION',
    title: 'AUTHENTICATION',
    component: '',
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'auth',
  },
];

export const signatureRoutes = [
  {
    path: '/templates',
    name: 'TEMPLATES',
    title: 'TEMPLATES',
    component: TemplatesPage,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'signature',
  },
  {
    path: '/headshot',
    name: 'HEADSHOT',
    title: 'HEADSHOT',
    component: Headshot,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'signature',
  },
  {
    path: '/design',
    name: 'DESIGN',
    title: 'DESIGN',
    component: Design,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'signature',
  },
  {
    path: '/cta',
    name: 'CALL TO ACTION',
    title: 'CALL TO ACTION',
    component: CallToAction,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [FEATURE_KEYS.CALL_TO_ACTION],
    fallbackComponent: CallToAction,
    layout: 'signature',
  },
  {
    path: '/analytics',
    name: 'ANALYTICS',
    title: 'ANALYTICS',
    component: Analytics,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [FEATURE_KEYS.SIGNATURE_ANALYTICS],
    fallbackComponent: SubsriptionPage,
    layout: 'signature',
  },
  {
    path: '/information',
    name: 'INFORMATION',
    title: 'INFORMATION',
    component: Information,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'signature',
  },
  {
    path: '/email-signature',
    name: 'EMAIL SIGNATURE',
    title: 'EMAIL SIGNATURE',
    component: EmailSignature,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'signature',
  },
];

export const onboardingRoutes = [
  {
    path: '/',
    name: 'ONBOARDING',
    title: 'ONBOARDING',
    component: Onboarding,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'onboarding',
  },
  {
    path: '/professional-headshot/:id',
    name: 'PROFESSIONAL HEADSHOT',
    title: 'PROFESSIONAL HEADSHOT',
    component: PrivateHeadshot,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'onboarding',
  },
  {
    path: '/info/:id',
    name: 'INFO',
    title: 'INFO',
    component: OnboardingInfo,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'onboarding',
  },
  {
    path: '/try-headshot/:id',
    name: 'TRY HEADSHOT',
    title: 'TRY HEADSHOT',
    component: PrivateHeadshotEditor,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'onboarding',
  },
];

export const userRoutes = [
  {
    path: '/subscriptions',
    name: 'SUBSCRIPTIONS',
    title: 'SUBSCRIPTIONS',
    component: SubsriptionPage,
    permission: [ROLES.OWNER],
    planWisePermission: [1, 2, 3, 6, 7, 8, 9, 10, 11, 12, 13],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/billing',
    name: 'BILLING',
    title: 'BILLING',
    component: BillingPage,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/profile',
    name: 'PROFILE',
    title: 'PROFILE',
    component: Profile,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/account',
    name: 'ACCOUNT',
    title: 'ACCOUNT',
    component: Account,
    permission: [ROLES.OWNER],
    planWisePermission: [4, 5, 11, 12, 13, 14],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/whitelabel',
    name: 'WHITELABEL',
    title: 'WHITELABEL',
    component: WhiteLabel,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [5, 14],
    featuresPermission: [],
    layout: 'user',
  },
];

export const aiHeadshotRoutes = [
  {
    path: '*',
    name: 'AIHEADSHOT',
    title: 'AIHEADSHOT',
    component: AiHeadshot,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'aiheadshot',
  },
];

export const dashboardRoutes = [
  {
    path: '/not-found',
    name: 'MAIN',
    title: 'MAIN',
    component: Main,
    permission: [ROLES.ALL],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'dashboard',
  },
  {
    path: '/logout',
    name: 'LOGOUT',
    title: 'LOGOUT',
    component: Logout,
    permission: [ROLES.ALL],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: null,
    layout: 'dashboard',
  },
];

export const workspaceRoutes = [
  {
    path: '/assets',
    name: 'ASSETS',
    title: 'My Signatures',
    component: Assets,
    permission: [ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/assets',
    name: 'ASSETS',
    title: 'My Signature',
    component: TeammateMySignature,
    permission: [ROLES.TEAMMATE, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/templates',
    name: 'TEMPLATES',
    title: 'Templates',
    component: Templates,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/templates/:wtid',
    name: 'TEMPLATEPAGE',
    title: 'Templates page',
    component: TemplateDialog,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/groups',
    name: 'GROUPS',
    title: 'Groups',
    component: Groups,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/groups/:groupId',
    name: 'GroupEdit',
    title: 'GroupEdit',
    component: GroupEdit,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/teammates',
    name: 'TEAMMATES',
    title: 'Teammates',
    component: Teammates,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/integrations',
    name: 'INTEGRATIONS',
    title: 'Integrations',
    component: Integrations,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [3, 4, 5, 6, 7, 8, 11, 12, 13, 14],
    featuresPermission: [],
    fallbackComponent: Integrations,
    layout: 'workspace',
  },
  {
    path: '/teammates/:workspaceTeammateId',
    name: 'TEAMMATES',
    title: 'Teammates',
    component: TeammateEdit,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/subscriptions',
    name: 'SUBSCRIPTIONS',
    title: 'SUBSCRIPTIONS',
    component: SubsriptionPage,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/profile',
    name: 'PROFILE',
    title: 'PROFILE',
    component: Profile,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  {
    path: '/analytics',
    name: 'ANALYTICS',
    title: 'Analytics',
    component: WorkspaceAnalytics,
    permission: [ROLES.OWNER, ROLES.MANAGER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    fallbackComponent: SubsriptionPage,
    layout: 'workspace',
  },
  {
    path: '/restrictedPage',
    name: 'RESTRICATIONS',
    title: 'Restrications',
    component: RestrictedContentCard,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspace',
  },
  // {
  //   path: '*',
  //   name: 'PROFILE',
  //   title: 'PROFILE',
  //   component: Main,
  //   permission: [ROLES.ALL],
  // planWisePermission:[],
  //   featuresPermission: [],
  // },
];

export const workspacesettingRoutes = [
  {
    path: '/profilesetting',
    name: 'ProfileSettting',
    title: 'ProfileSetting',
    component: ProfileSetting,
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'workspacesetting',
  },
];
// export default {
//   publicRoutes,
//   authRoutes,
//   signatureRoutes,
//   onboardingRoutes,
//   dashboardRoutes,
// };
