/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_VALID_ACCESS_TOKEN = gql`
  query GetValidAccessToken {
    getValidAccessToken {
      status
    }
  }
`;

export const GET_WORKSPACE_CONNECTION_STATUS = gql`
  query GetWorkspaceConnectionStatus($where: ConnectGoogleMarketplaceInput!) {
    getWorkspaceConnectionStatus(where: $where) {
      isConnected
    }
  }
`;

export const GET_GOOGLE_AUTH_TOKEN = gql`
  query GetGoogleAuthToken($where: GetGoogleAuthTokenInput!) {
    getGoogleAuthToken(where: $where) {
      success
      message
      accessToken
    }
  }
`;

export const GET_GOOGLE_IMAGE_SIGNED_URL = gql`
  query GetGoogleImageUploadSignedUrl(
    $data: GetGoogleImageUploadSignedUrlInput!
  ) {
    getGoogleImageUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;
