/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import TemplateCard from 'modules/Templates/components/Template/components/TemplateCard';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useRouter } from 'hooks/router';
import SignatureCard from 'modules/Assets/components/Signatures/SignatureCard';

// Zod validation schema
const groupSchema = z.object({
  name: z
    .string()
    .min(1, 'Group name is required')
    .refine((value) => value.trim() !== '', 'Group name is required'),
});

const CreateForm = ({ data, onClose, handleOnClick }) => {
  const {
    state: { workspaceTemplates },
  } = useWorkspaceContext();
  const {
    navigate,
    params: { workspaceId },
  } = useRouter();
  const form = useForm({
    resolver: zodResolver(groupSchema),
    defaultValues: {
      name: data?.name ?? '',
    },
  });

  const [selectedTemplate, setSelectedTemplate] = useState(
    workspaceTemplates[0],
  );

  const handleTemplateCardOnClick = (template) => {
    setSelectedTemplate(template);
  };

  const handleFormSubmit = (values) => {
    handleOnClick({ ...values, templateId: selectedTemplate?.id });
  };

  return (
    <DialogContent
      className="rounded-[6px] max-w-md px-[21px] py-[22px]"
      hideCloseButton
    >
      <DialogHeader>
        <DialogTitle>Create Group</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleFormSubmit)}>
          <div className="grid gap-[18px]">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Group Name</FormLabel>
                  <FormControl>
                    <Input
                      id="name"
                      placeholder="Enter Group Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <p className="m-0 p-0 text-[14px]">Select Template</p>
            <div className="flex gap-4  overflow-auto custom-scrollbar">
              <div className="max-w-[855px] ">
                {workspaceTemplates.length > 0 ? (
                  <div className="flex p-2 gap-3">
                    {workspaceTemplates.map((item) => (
                      <div key={item?.id}>
                        <SignatureCard
                          signature={item}
                          key={item?.id}
                          handleSignatureCardOnClick={handleTemplateCardOnClick}
                          hideAction
                          size="sm"
                          signatureCardClassname={`max-w-[200px] min-w-[200px] h-[130px] m-0 ${
                            selectedTemplate?.id === item?.id
                              ? 'shadow-signature'
                              : ''
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-8">
                    <div className="text-lg font-semibold text-gray-700 mb-4">
                      No templates available
                    </div>
                    <Button
                      onClick={() => {
                        navigate(`/app/workspace/${workspaceId}/templates`);
                      }}
                      className="px-4 py-2  text-white-0 rounded  transition"
                    >
                      Add Template
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <DialogFooter>
              <div className="flex justify-end gap-3">
                <Button
                  type="button"
                  variant="outline"
                  className="w-[max-content]"
                  onClick={onClose}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm">
                  Create
                </Button>
              </div>
            </DialogFooter>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
};

export default CreateForm;
