/* eslint-disable no-console */
/* eslint-disable no-undef */
import client from 'apollo';
import { fileUpload } from 'common/utils';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogOverlay } from 'components/ui/dialog';
import { Slider } from 'components/ui/slider';
import { useRouter } from 'hooks/router';
import { cn } from 'lib/utils';
import { GET_CLIENT_IMAGE_SIGNED_URL } from 'modules/Assets/graphql/Queries';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

const CropperDialog = ({
  open = false,
  onClose,
  file,
  finalImage,
  cropperClass,
  handleFinish,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const {
    location: { pathname },
  } = useRouter();

  const handleImageUpload = async (file) => {
    const { name } = file || {};

    const ext = name.substring(name.lastIndexOf('.') + 1);
    const filename = name.split('.').slice(0, -1).join('.');
    const newFilename = `${filename}-${Date.now()}.${ext}`;
    try {
      const {
        data: { getClientImageSignedUrl },
      } = await client?.query({
        query: GET_CLIENT_IMAGE_SIGNED_URL,
        variables: { data: { fileName: newFilename } },
      });
      // const uploadResponse =
      const res = await fileUpload(getClientImageSignedUrl?.url, file);
      const imageUrlRes = res?.config?.url.split('?')[0];
      return imageUrlRes;
    } catch (error) {
      console.log('error', error);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation,
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    // Convert cropped image to Blob
    const blob = await new Promise((resolve) => {
      croppedCanvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });

    // Create a new file object
    const newFile = new File([blob], 'cropped-image.jpg');
    // Assign preview URL to the file object
    // Object.assign(newFile, {
    //   preview: URL.createObjectURL(blob),
    // });
    // Return the file object
    return newFile;
  }

  const [croppedImageResult, setCroppedImageResult] = useState('');
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        file[0]?.preview,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImageResult(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const showCroppedImage = async () => {
    if (handleFinish) {
      await handleFinish(croppedImageResult);
    } else {
      const awsUrl = await handleImageUpload(croppedImageResult);
      finalImage(awsUrl);
    }
  };
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogOverlay className="bg-[black]/80" />
      <DialogContent
        className={cn('p-0 gap-0 max-w-[550px]', cropperClass)}
        hideCloseButton
      >
        <div className=" p-2 rounded-md ">
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: 500,
              background: '#333',
            }}
          >
            <Cropper
              image={file?.[0]?.preview}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="p-4">
            {pathname.includes('ai-headshot') ? (
              <p className="text-[white]">Zoom</p>
            ) : (
              <p>Zoom</p>
            )}

            <Slider
              defaultValue={[1]}
              min={1}
              max={3}
              step={0.1}
              className="w-full"
              onValueChange={([zoom]) => {
                setZoom(zoom);
              }}
            />
          </div>
          <div className="flex gap-2 justify-end p-4">
            <Button onClick={onClose} size="sm">
              Remove
            </Button>
            <Button onClick={showCroppedImage} size="sm">
              OK
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CropperDialog;
