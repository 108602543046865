/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */

import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useAppContext } from 'AppContext';
import {
  REMOVE_SIGNATURE,
  SEND_SIGNATURE,
  UPSERT_SIGNATURE,
} from 'modules/Signature/graphql/Mutations';
import { cloneAndRemoveTypename, uploadAllImages } from 'common/utils';
import {
  GET_SIGNATURE,
  GET_SIGNATURE_LISTING,
  GET_TEAMMATE_SIGNATURE,
} from 'modules/Signature/graphql/Queries';
import { toast } from 'hooks/use-toast';
import { isValidEmail, isValidUrl } from 'lib/utils';
import { FIELDS_WITH_ICON, UTAGSFIELDS } from 'common/constants';
import { delay } from 'modules/AiHeadshot/components/HeadshotGenerator/HeadshotGenerator';
import { isEqual } from 'lodash';
import { useRouter } from './router';
import { useWorkspaceId } from './workspace';
import { useFeatures } from './user';

export const useSignatureId = () => {
  const {
    state: { signature },
  } = useAppContext();
  const {
    params: { id: signatureId },
  } = useRouter();

  return { signatureId: signatureId || signature?.id };
};
export const useUpsertSignature = () => {
  const { setSignature } = useAppContext();
  const [upsertSignature, states] = useMutation(UPSERT_SIGNATURE, {
    onCompleted(data) {
      const signature = cloneAndRemoveTypename(data?.upsertSignature);

      setSignature(signature);
    },
    onError() {},
  });

  return [upsertSignature, states];
};
export const useSendSignature = () => {
  const [sendSignature] = useMutation(SEND_SIGNATURE, {
    onCompleted() {},
    onError() {},
  });

  return [sendSignature];
};
export const useGetSignature = () => {
  const { setSignature } = useAppContext();

  const [getSignature, states] = useLazyQuery(GET_SIGNATURE, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const signature = cloneAndRemoveTypename(data?.signature);
      setSignature(signature);
    },
    onError() {
      // eslint-disable-next-line no-undef
    },
  });

  return [getSignature, states];
};
export const useGetSignatureResponse = () => {
  const [getSignature, states] = useLazyQuery(GET_SIGNATURE, {
    fetchPolicy: 'network-only',
    onCompleted(data) {},
    onError() {
      // eslint-disable-next-line no-undef
      window.location.href = '/app/';
    },
  });

  return [getSignature, states];
};

export const useFetchTeammateSignature = () => {
  const [getTeammateSignature, states] = useLazyQuery(GET_TEAMMATE_SIGNATURE, {
    fetchPolicy: 'network-only',
    onCompleted(data) {},
    onError() {},
  });

  return [getTeammateSignature, states];
};

export const useGetSignatureList = () => {
  const [getSignatureListing, states] = useLazyQuery(GET_SIGNATURE_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted() {},
  });

  return [getSignatureListing, states];
};

export const useValueChanged = (key, value) => {
  // State to track if the value has changed.
  const [isValueChanged, setIsValueChanged] = useState(false);

  useEffect(() => {
    if (!value) return;
    // eslint-disable-next-line no-undef
    const previousValue = localStorage.getItem(key);
    const hasChanged = previousValue !== JSON.stringify(value);
    setIsValueChanged(hasChanged);
    // eslint-disable-next-line no-undef
    localStorage.setItem(key, JSON.stringify(value));
  }, []); // Re-run this effect when `value` or `key` changes.

  return isValueChanged;
};

export const useDeleteSignature = () => {
  const [deleteSignature] = useMutation(REMOVE_SIGNATURE, {
    onCompleted(res) {},
    onError() {},
  });
  return [deleteSignature];
};

export const useSaveSignature = () => {
  const {
    state: { signature, baseImages },
  } = useAppContext();
  const {
    params: { id: signatureId },
  } = useRouter();
  const { workspaceId } = useWorkspaceId();

  const [loading, setLoading] = useState(false);
  const [oldSignature, setOldSignature] = useState(null);
  const [upsertSignature] = useUpsertSignature();

  const signatureIconCount = signature?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );
  const oldSignatureIconCount = oldSignature?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );

  const isDesignStyleChanged = !isEqual(
    oldSignature?.design,
    signature?.design,
  ) || !isEqual(signatureIconCount, oldSignatureIconCount)

  const oldSignaturefieldsValue = oldSignature?.fields
    ?.map((f) => f.value)
    ?.filter((v) => !!v)
    ?.sort();
  const newSignaturefieldsValue = signature?.fields
    ?.map((f) => f.value)
    ?.filter((v) => !!v)
    ?.sort();

  const isSignatureFieldChanged = !isEqual(
    oldSignaturefieldsValue,
    newSignaturefieldsValue,
  );

  const [getSignature] = useLazyQuery(GET_SIGNATURE, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const response = cloneAndRemoveTypename(data?.signature);
      setOldSignature(response);
    },
    onError() {
      toast({
        closeicn: 'destructive',
        description: 'Failed to load the original signature data.',
      });
    },
  });

  useEffect(() => {
    if (signatureId) {
      getSignature({ variables: { where: { signatureId, workspaceId } } });
    }
  }, [signatureId]);

  const saveChanges = async () => {
    setLoading(true);
    if ((isSignatureFieldChanged || isDesignStyleChanged) && signatureId) {
      const signatureFieldsWithValues = signature?.fields?.map((f) => {
        if (UTAGSFIELDS.includes(f?.name)) {
          if (f.name === 'email' || f.name === 'companyEmail') {
            if (!isValidEmail(f.value)) {
              return { ...f, value: '' };
            }
            return f;
          }
          if (!isValidUrl(f.value)) {
            return { ...f, value: '' };
          }
        }
        return f;
      });

      const { id, ...data } = signature;
      const restData = { ...data };
      const response = await uploadAllImages(baseImages, signatureId);
      restData.design.icons = response;
      try {
        const res = await upsertSignature({
          variables: {
            data: {
              ...restData,
              fields: signatureFieldsWithValues,
            },
            where: { signatureId: id || signatureId, workspaceId },
          },
        });

        if (res?.data) {
          toast({
            closeicn: 'success',
            description: 'Changes saved successfully',
          });
          setOldSignature(signature); // Update oldSignature to reflect the saved changes
          await delay(1000);
        }
      } catch (error) {
        toast({
          closeicn: 'destructive',
          description: 'Failed to save changes.',
        });
      }
    }

    setLoading(false);
  };

  return {
    saveChanges,
    loading,
    isSignatureChanged: isSignatureFieldChanged || isDesignStyleChanged,
    isDesignStyleChanged,
  };
};

export const useShowAwsIcons = (show = true, revert = true) => {
  const { setshowAwsIcons } = useAppContext();

  useEffect(() => {
    setshowAwsIcons(show);
    return () => {
      if (revert) {
        setshowAwsIcons(!show);
      }
    };
  }, []);
};

export const useShowBranding = (show = true, revert = true) => {
  const { setShowBranding } = useAppContext();

  useEffect(() => {
    setShowBranding(show);
    return () => {
      if (revert) {
        setShowBranding(!show);
      }
    };
  }, []);
};
export const useShowBrandingText = (show = true, revert = true) => {
  const { setShowBrandingText } = useAppContext();

  useEffect(() => {
    setShowBrandingText(show);
    return () => {
      if (revert) {
        setShowBrandingText(!show);
      }
    };
  }, []);
};
