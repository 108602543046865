import React from 'react';
import {
  FONT_FAMILY,
  SOCIAL_LINKS,
  contactIconLinks,
  TEXT_IDS,
} from 'common/constants';
import { getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import HeadshotImage from './components/HeadshotImage';

import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import CompanyAddress from './components/CompanyAddress';
import Website from './components/Website';
import CompanyLogo from './components/CompanyLogo';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

export const getFieldData = (fields, name) => {
  const res = fields?.find((field) => field.name === name);
  if (res?.value) {
    return res;
  }
  return null;
};

function TemplateStyleTwo({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;

  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const allUrlsAreNull =
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const socialAreNull = socialFields?.every((obj) => obj?.value === '');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  const contentWidth = get(design, 'styles.content.style.width', 600);
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        borderCollapse="collapse"
        style={{
          margin: '0 !important',
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize: 'inherit',
              paddingBottom: '2px',
            }}
          >
            <table
              ref={mainContentRef}
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <tr>
                <td
                  style={{
                    fontSize: '0px',
                    lineHeight: '0px',
                    verticalAlign: 'top',
                    paddingRight: '14px',
                  }}
                >
                  {!section1 && (
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      style={{
                        fontSize: 'inherit',
                      }}
                    >
                      {getFieldData(fields, 'headshotUrl') && (
                        <tr align="center">
                          {HeadshotImage({
                            data: getFieldData(fields, 'headshotUrl'),
                            design,
                            style: {
                              paddingBottom: '8px',
                            },
                          })}
                        </tr>
                      )}
                      {getFieldData(fields, 'companyLogo') && (
                        <tr align="center">
                          {CompanyLogo({
                            design,
                            data: getFieldData(fields, 'companyLogo'),
                            style: {
                              paddingBottom: '8px',
                            },
                          })}
                        </tr>
                      )}
                    </table>
                  )}
                </td>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    style={{
                      fontSize: 'inherit',
                      verticalAlign: 'top',
                    }}
                  >
                    {!section2 && (
                      <tr>
                        <td>
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                            style={{
                              fontSize: 'inherit',
                              paddingBottom: '6px',
                            }}
                          >
                            {getFieldData(fields, 'name') && (
                              <tr>
                                {Name({
                                  signature,
                                  data: getFieldData(fields, 'name'),
                                  style: {
                                    fontWeight: 700,
                                    color: design?.primaryBrandColor,
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'position') && (
                              <tr>
                                {Position({
                                  signature,
                                  data: getFieldData(fields, 'position'),
                                  style: {},
                                })}
                              </tr>
                            )}
                            {(getFieldData(fields, 'department') ||
                              getFieldData(fields, 'companyName')) && (
                              <tr>
                                <td>
                                  <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                    style={{
                                      fontSize: 'inherit',
                                    }}
                                  >
                                    <tr>
                                      {getFieldData(fields, 'department') &&
                                        Department({
                                          signature,
                                          data: getFieldData(
                                            fields,
                                            'department',
                                          ),
                                          style: {
                                            paddingRight: '8px',
                                          },
                                        })}
                                      {getFieldData(fields, 'companyName') &&
                                        CompanyName({
                                          signature,
                                          data: getFieldData(
                                            fields,
                                            'companyName',
                                          ),
                                        })}
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}

                    {!allUrlsAreNull && (
                      <tr>
                        <td
                          height={8}
                          className={TEXT_IDS.SEPARATOR}
                          colSpan={2}
                          style={{
                            borderTop: !socialAreNull
                              ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                              : '',
                            lineHeight: 0,
                            margin: 0,
                          }}
                        />
                      </tr>
                    )}
                    {!allUrlsAreNull && (
                      <tr>
                        <td>
                          <table
                            cellPadding="0px"
                            borderCollapse="collapse"
                            style={{
                              fontSize: 'inherit',
                              paddingBottom: '8px',
                            }}
                          >
                            {getFieldData(fields, 'phone') && (
                              <tr>
                                {Phone({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'phone'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyPhone') && (
                              <tr>
                                {CompanyPhone({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyPhone'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'email') && (
                              <tr>
                                {Email({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'email'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyEmail') && (
                              <tr>
                                {CompanyEmail({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyEmail'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'website') && (
                              <tr>
                                {Website({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'website'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyAddress') && (
                              <tr>
                                {CompanyAddress({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyAddress'),
                                  style: { paddingBottom: '0px' },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}

                    {!socialAreNull && (
                      <tr>
                        <td
                          height={8}
                          className={TEXT_IDS.SEPARATOR}
                          colSpan={2}
                          style={{
                            borderTop: !socialAreNull
                              ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                              : '',
                            lineHeight: 0,
                            margin: 0,
                          }}
                        />
                      </tr>
                    )}

                    {!socialAreNull && (
                      <tr>
                        <td
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                            borderCollapse="collapse"
                          >
                            {!!socialFields?.length && (
                              <tr>
                                {SocialLinks({
                                  signature,
                                  showAwsIcons,
                                  data: socialFields,
                                  design,
                                  style: {
                                    paddingBottom: '6px',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleTwo;
