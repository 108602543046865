import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtyFive({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyName') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'companyLogo') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  const contentWidth = get(design, 'styles.content.style.width', 600);
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          margin: '0 !important',
          padding: '0 !important',
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr style={{}}>
          <td>
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              role="presentation"
              style={{
                fontSize: 'inherit',
                paddingBottom: '2px',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    ref={mainContentRef}
                    style={{
                      fontSize: 'inherit',
                    }}
                  >
                    {!section2 && (
                      <tr>
                        <td
                          style={{
                            paddingBottom: '6px',
                          }}
                        >
                          <table
                            cellPadding="0px"
                            cellSpacing="0"
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'name') && (
                              <tr>
                                {Name({
                                  signature,
                                  data: getFieldData(fields, 'name'),
                                  style: {
                                    color: design?.primaryBrandColor,
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'position') && (
                              <tr>
                                {Position({
                                  signature,
                                  data: getFieldData(fields, 'position'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '10px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'department') && (
                              <tr>
                                {Department({
                                  signature,
                                  data: getFieldData(fields, 'department'),
                                  style: {
                                    fontFamily: 'inherit',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'phone') && (
                              <tr>
                                {Phone({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'phone'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '0px',
                                  },
                                  customWord: 'telefon:',
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'email') && (
                              <tr>
                                {Email({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'email'),
                                  style: {
                                    fontFamily: 'inherit',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                        <td>
                          <table
                            cellPadding="0px"
                            cellSpacing="0"
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr>
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                  align: 'left',
                                  style: {
                                    paddingBottom: '0px',
                                    textAlign: 'left',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                    {!allUrlsAreNull && (
                      <tr>
                        <td
                          height={14}
                          className={TEXT_IDS.SEPARATOR}
                          colSpan={2}
                          style={{
                            borderTop: !allUrlsAreNull
                              ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                              : '',
                            lineHeight: 0,
                            margin: 0,
                          }}
                        />
                      </tr>
                    )}
                    {!allUrlsAreNull && (
                      <tr>
                        <td
                          style={{
                            paddingBottom: '6px',
                          }}
                        >
                          <table
                            cellPadding="0px"
                            cellSpacing="0"
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'companyName') && (
                              <tr>
                                {CompanyName({
                                  signature,
                                  data: getFieldData(fields, 'companyName'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyAddress') && (
                              <tr>
                                {CompanyAddress({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyAddress'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyPhone') && (
                              <tr>
                                {CompanyPhone({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyPhone'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '0px',
                                  },
                                  customWord: 'telefon:',
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyEmail') && (
                              <tr>
                                {CompanyEmail({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'companyEmail'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '0px',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'website') && (
                              <tr>
                                {Website({
                                  signature,
                                  showAwsIcons,
                                  design,
                                  data: getFieldData(fields, 'website'),
                                  style: {
                                    fontFamily: 'inherit',
                                    paddingBottom: '10px',
                                  },
                                })}
                              </tr>
                            )}
                            {!!socialFields?.length && (
                              <tr>
                                {SocialLinks({
                                  signature,
                                  showAwsIcons,
                                  data: socialFields,
                                  design,
                                  style: {},
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                        <td>
                          <table
                            cellPadding="0px"
                            cellSpacing="0"
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            {getFieldData(fields, 'companyLogo') && (
                              <tr>
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                  style: { paddingBottom: '6px' },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                    {!allUrlsAreNull && (
                      <tr>
                        <td
                          height={14}
                          className={TEXT_IDS.SEPARATOR}
                          colSpan={2}
                          style={{
                            borderTop: !allUrlsAreNull
                              ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                              : '',
                            lineHeight: 0,
                            margin: 0,
                          }}
                        />
                      </tr>
                    )}
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
        order={[
          'Footer',
          'CallToActionBanner',
          'CallToAction',
          'SocialBanners',
        ]}
        styles={{
          paddingBottom: '50px',
        }}
      />
    </>
  );
}

export default TemplateStyleThirtyFive;
