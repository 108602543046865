/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtyOne({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';

  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const contentWidth = get(design, 'styles.content.style.width', 600);
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            aria-label="main-content"
            style={{
              marginBottom: '8px',
            }}
          >
            <table
              cellPadding="0px"
              cellSpacing="0"
              border="0"
              ref={mainContentRef}
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                {!!getFieldData(fields, 'headshotUrl') && (
                  <td
                    style={{
                      verticalAlign: 'top',
                      paddingRight: '14px',
                    }}
                  >
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        fontSize: 'inherit',
                      }}
                    >
                      {getFieldData(fields, 'headshotUrl') && (
                        <tr align="center">
                          {HeadshotImage({
                            data: getFieldData(fields, 'headshotUrl'),
                            design,
                          })}
                        </tr>
                      )}
                    </table>
                  </td>
                )}
                <td
                  style={{
                    verticalAlign: 'top',
                    paddingRight: '14px',
                  }}
                >
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    style={{
                      borderCollapse: 'collapse',
                    }}
                  >
                    <tr>
                      {getFieldData(fields, 'name') &&
                        Name({
                          signature,
                          data: getFieldData(fields, 'name'),
                          style: {
                            fontWeight: 700,
                            color: design?.primaryBrandColor,
                            fontFamily: 'inherit',
                          },
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'position') &&
                        Position({
                          signature,
                          data: getFieldData(fields, 'position'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'department') &&
                        Department({
                          signature,
                          data: getFieldData(fields, 'department'),
                          style: {
                            fontFamily: 'inherit',
                            paddingRight: '8px',
                          },
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'companyName') &&
                        CompanyName({
                          signature,
                          data: getFieldData(fields, 'companyName'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                    </tr>

                    <tr>
                      {getFieldData(fields, 'phone') &&
                        Phone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'phone'),
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'email') &&
                        Email({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'email'),
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'companyPhone') &&
                        CompanyPhone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyPhone'),
                          style: {
                            paddingRight: '8px',
                          },
                        })}
                    </tr>
                    <tr>
                      {getFieldData(fields, 'companyEmail') &&
                        CompanyEmail({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyEmail'),
                          style: {},
                        })}
                    </tr>
                    {getFieldData(fields, 'website') && (
                      <tr>
                        {Website({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'website'),
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyAddress') && (
                      <tr>
                        {CompanyAddress({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyAddress'),
                        })}
                      </tr>
                    )}
                    {!!socialFields?.length && (
                      <tr>
                        {SocialLinks({
                          signature,
                          showAwsIcons,
                          data: socialFields,
                          design,
                        })}
                      </tr>
                    )}
                  </table>
                </td>
                {!!getFieldData(fields, 'companyLogo') && (
                  <td
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        fontSize: 'inherit',
                      }}
                    >
                      <tr>
                        {getFieldData(fields, 'companyLogo') &&
                          CompanyLogo({
                            data: getFieldData(fields, 'companyLogo'),
                            design,
                            style: {
                              textAlign: 'center',
                            },
                          })}
                      </tr>
                    </table>
                  </td>
                )}
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleThirtyOne;
