/* eslint-disable no-undef */
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Papa from 'papaparse';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { UploadFile } from 'components/Inputs';
import SelectUsersDialogCSV from './SelectUsersDialogCSV';

const UploadTeammatesCSV = forwardRef((_, ref) => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(true); // New state
  const selectUsersDialogRef = useRef();

  // Use imperative handle to expose open/close methods
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setUploadDisabled(false); // Enable the button when a file is selected
  //     Papa.parse(file, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: (results) => {
  //         // eslint-disable-next-line no-console
  //         console.log(results.data);
  //         setCsvData(results.data);
  //         selectUsersDialogRef.current.open();
  //       },
  //     });
  //   } else {
  //     setUploadDisabled(true); // Disable the button if no file is selected
  //   }
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadDisabled(false); // Enable the button when a file is selected
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data.map((row, index) => ({
            ...row,
            id: index,
          }));
          // eslint-disable-next-line no-console
          console.log(parsedData);
          setCsvData(parsedData);
          selectUsersDialogRef.current.open();
        },
      });
    } else {
      setUploadDisabled(true); // Disable the button if no file is selected
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    selectUsersDialogRef.current.open();
    setLoading(false);
  };

  const handleSelectUsersDialogClose = () => {
    setIsOpen(false);
  };

  const handleDownloadSampleCSV = () => {
    const link = document.createElement('a');
    link.href =
      'https://send-sig-dev.s3.ap-south-1.amazonaws.com/asset/sample_teammate.csv';
    link.download = 'sample_teammate.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-lg max-w-lg p-6 bg-white-0 shadow-lg">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Upload Teammates via CSV
        </h2>
        <p className="text-sm text-gray-600 mb-2">
          Choose a CSV file with teammate details. You can also download a
          sample file to see the required format.
        </p>
        <div className="space-y-4">
          <UploadFile
            id="csv-upload"
            label=""
            accept=".csv"
            onChange={handleFileUpload}
            className="w-full"
          />
          <div className="flex items-center justify-between">
            <Button
              onClick={handleSubmit}
              disabled={loading || uploadDisabled} // Disable when loading or no file is selected
              className="px-4 py-2 rounded-md"
            >
              {loading ? 'Uploading...' : 'Upload Teammates'}
            </Button>
            <Button
              onClick={handleDownloadSampleCSV}
              className="px-4 py-2 rounded-md"
              variant="link"
            >
              Download Sample CSV
            </Button>
          </div>
        </div>
        <SelectUsersDialogCSV
          ref={selectUsersDialogRef}
          users={csvData}
          onClose={handleSelectUsersDialogClose}
        />
      </DialogContent>
    </Dialog>
  );
});

export default UploadTeammatesCSV;
