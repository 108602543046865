import { useAppContext } from 'AppContext';
import UserProfile from 'components/UserProfile';
import { UserOutlined } from '@ant-design/icons';
import { useRouter } from 'hooks/router';
import React from 'react';
import { ReceiptText, Tag, Tags } from 'lucide-react';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { PLAN_ID_ARRAY, ROLES } from 'common/constants';
import { useWorkspaceId } from 'hooks/workspace';

export const userRoutes = [
  {
    path: '/subscriptions',
    name: 'SUBSCRIPTIONS',
    title: 'SUBSCRIPTIONS',
    permission: [ROLES.OWNER],
    planWisePermission: [1, 2, 3, 6, 7, 8, 9, 10],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/billing',
    name: 'BILLING',
    title: 'BILLING',
    permission: [ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/profile',
    name: 'PROFILE',
    title: 'PROFILE',
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [...PLAN_ID_ARRAY],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/account',
    name: 'ACCOUNT',
    title: 'ACCOUNT',
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [4, 5, 11, 12, 13, 14],
    featuresPermission: [],
    layout: 'user',
  },
  {
    path: '/whitelabel',
    name: 'WHITELABEL',
    title: 'WHITELABEL',
    permission: [ROLES.ALL, ROLES.OWNER],
    planWisePermission: [5, 14],
    featuresPermission: [],
    layout: 'user',
  },
];

function ProfileAvatar() {
  const { navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();
  const {
    state: { currentUser },
  } = useAppContext();

  const TAB_CONTENT_MAP = {
    ACCOUNT: {
      key: 'Account',
      label: (
        <div
          // onClick={() => navigate(`/app/${workspaceId}/user/account`)}
          className="flex w-64 cursor-pointer"
        >
          <div className="flex-grow pointer-events-none">Account</div>
          <div className="flex items-start justify-center pointer-events-none">
            <UserOutlined />
          </div>
        </div>
      ),
      onClick: () => navigate(`/app/${workspaceId}/user/account`),
    },
    PROFILE: {
      key: 'Profile',
      label: (
        <div className="flex w-64 cursor-pointer">
          <div className="flex-grow pointer-events-none">Profile</div>
          <div className="flex items-start justify-center pointer-events-none">
            <UserOutlined />
          </div>
        </div>
      ),
      onClick: () => navigate(`/app/${workspaceId}/user/profile`),
    },
    SUBSCRIPTIONS: {
      key: 'subscription',
      label: (
        <div
          // onClick={() => navigate(`/app/${workspaceId}/user/subscriptions`)}
          className="flex w-64 cursor-pointer"
        >
          <div className="flex-grow pointer-events-none">Plans</div>
          <div className="flex items-start justify-center pointer-events-none">
            <Tag size={15} />
          </div>
        </div>
      ),
      onClick: () => navigate(`/app/${workspaceId}/user/subscriptions`),
    },
    BILLING: {
      key: 'billing',
      label: (
        <div
          // onClick={() => navigate(`/app/${workspaceId}/user/billing`)}
          className="flex w-64 cursor-pointer"
        >
          <div className="flex-grow pointer-events-none">Billing</div>
          <div className="flex items-start justify-center pointer-events-none">
            <ReceiptText size={15} />
          </div>
        </div>
      ),
      onClick: () => navigate(`/app/${workspaceId}/user/billing`),
    },
    WHITELABEL: {
      key: 'whitelabel',
      label: (
        <div
          // onClick={() => navigate(`/app/${workspaceId}/user/whitelabel`)}
          className="flex w-64 cursor-pointer"
        >
          <div className="flex-grow pointer-events-none">WhiteLabel</div>
          <div className="flex items-start justify-center pointer-events-none">
            <Tags size={15} />
          </div>
        </div>
      ),
      onClick: () => navigate(`/app/${workspaceId}/user/whitelabel`),
    },
  };
  const { getRoutesList } = useGetRoutes();
  const routes = getRoutesList(userRoutes);

  const getTabItem = (key) => {
    const route = routes.find((r) => r.name === key);
    if (route) {
      return [
        {
          ...route,
          ...TAB_CONTENT_MAP[key],
        },
      ];
    }
    return [];
  };

  const extraItems = [
    ...getTabItem('ACCOUNT'),
    ...getTabItem('PROFILE'),
    ...getTabItem('SUBSCRIPTIONS'),
    ...getTabItem('BILLING'),
    ...getTabItem('WHITELABEL'),
    // {
    //   key: 'Profile',
    //   label: (
    //     <div
    //       onClick={() => navigate('/app/user/profile')}
    //       className="flex w-64 cursor-pointer"
    //     >
    //       <div className="flex-grow">Profile</div>
    //       <div className="flex items-start justify-center">
    //         <UserOutlined />
    //       </div>
    //     </div>
    //   ),
    // },
    // ...(brandOwnerTabs
    //   ? [
    //       {
    //         key: 'subscription',
    //         label: (
    //           <div
    //             onClick={() => navigate('/app/user/subscriptions')}
    //             className="flex w-64 cursor-pointer"
    //           >
    //             <div className="flex-grow">Plans</div>
    //             <div className="flex items-start justify-center">
    //               <Tag size={15} />
    //             </div>
    //           </div>
    //         ),
    //       },
    //     ]
    //   : []),
    // ...(brandOwnerTabs &&
    // ![
    //   'https://app.syncsignature.com',
    //   'https://app.dev.syncsignature.com',
    // ].includes(brand?.url)
    //   ? [
    //       {
    //         key: 'whitelabel',
    //         label: (
    //           <div
    //             onClick={() => navigate('/app/user/whitelabel')}
    //             className="flex w-64 cursor-pointer"
    //           >
    //             <div className="flex-grow">WhiteLabel</div>
    //             <div className="flex items-start justify-center">
    //               <Tags size={15} />
    //             </div>
    //           </div>
    //         ),
    //       },
    //     ]
    //   : []),
  ];

  const isFreeUser = currentUser?.activePlan?.id === '1';
  const isCouponAvailable = !currentUser?.isCouponUsed;

  return (
    <div className="text-primary-1000   flex items-center   gap-[22px]">
      {/* {isCouponAvailable && isFreeUser && <ApplyCoupon />} */}
      <UserProfile extraItems={extraItems} />
    </div>
  );
}

export default ProfileAvatar;
