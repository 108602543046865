import { socialImages } from 'assets/social-images';
import { SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection } from 'common/utils';
import { get, map } from 'lodash';
import React from 'react';

const SocialLinkTypeWrapper = ({
  type,
  styles,
  design,
  children,
  filename,
  signature,
  showAwsIcons,
  isLastItem = false,
}) => {
  const child = {
    // letter: filename.charAt(0).toUpperCase(),
    word: filename.charAt(0).toUpperCase() + filename.slice(1),
    rounded: children,
    square: children,
    none: '',
  };

  const backgroundColor = get(styles, 'socialIcon.style.color');

  const updatedStyle = {
    // letter: {
    //   fontSize: styles?.fontSize,
    //   backgroundColor: 'transparent',
    //   color: styles?.color || '#000',
    //   fontWeight: 700,
    //   maxWidth: '100%',
    //   padding: '4px',
    //   border: `2px solid ${backgroundColor}`,
    //   borderRadius: '50%',
    //   display: 'inline-flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // },
    word: {
      fontSize: styles?.fontSize,
      backgroundColor: 'transparent',
      color: backgroundColor || design?.primaryBrandColor,
      maxWidth: '100%',
      paddingRight: '8px',
      display: 'inline-block',
      borderRight: isLastItem ? 'none' : '1px solid',
      borderColor: backgroundColor || design?.primaryBrandColor,
    },
    rounded: { borderRadius: '50%' },
    square: { borderRadius: '5px' },
  };

  const content = ['letter', 'word'].includes(type) ? (
    <span
      id={`${TEXT_IDS.SOCIALLINKWORD}-${filename.toLowerCase()}`}
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
      }}
    >
      {child[type] || ''}
    </span>
  ) : (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        marginTop: '1px',
        marginBottom: '1px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key={filename}
        fileName={filename}
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};
function SocialLinks({
  style = {},
  design,
  tdProps = {},
  alignment = 'horizontal',
  signature = {},
  data = [],
  showAwsIcons = false,
}) {
  const { styles } = get(signature, 'design', {});
  const type = get(styles, 'socialIcon.type', 'rounded');
  const size = get(styles, 'socialIcon.style.size', 16);
  const backgroundColor = get(styles, 'socialIcon.style.color');

  return (
    <td
      style={{
        paddingBottom: style.paddingBottom || '6px',
        ...style,
      }}
      {...tdProps}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        style={{
          borderCollapse: 'collapse',
          fontSize: 'inherit',
        }}
      >
        <tbody>
          {alignment === 'horizontal' ? (
            <tr>
              {map(data, (social, index) => (
                <td
                  style={{
                    ...(index !== data.length - 1 && { paddingRight: '8px' }),
                    ...(!social?.value && { display: 'none' }),
                  }}
                  key={`${social?.value}-${index}`}
                >
                  <a
                    href={
                      social?.utagEnabled && social?.shortLink
                        ? social?.shortLink
                        : handleUrlRedirection(social?.value)
                    }
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <SocialLinkTypeWrapper
                      signature={signature}
                      design={design}
                      showAwsIcons={showAwsIcons}
                      filename={social?.name}
                      type={type}
                      styles={{
                        // height: size,
                        // width: size,
                        height:
                          type === 'letter' || type === 'word' ? 'auto' : size,
                        width:
                          type === 'letter' || type === 'word' ? 'auto' : size,
                        backgroundColor:
                          backgroundColor || design?.primaryBrandColor,
                        fontSize: size - 4,
                        display: 'block',
                      }}
                      isLastItem={index === data.length - 1}
                    >
                      {['letter', 'word'].includes(type) ? null : (
                        <img
                          src={
                            socialImages?.[
                              SOCIAL_LINKS.find((s) => s.name === social?.name)
                                .key
                            ]
                          }
                          style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                          }}
                          width="100%"
                          alt={`${social?.name}-icon`}
                        />
                      )}
                    </SocialLinkTypeWrapper>
                  </a>
                </td>
              ))}
            </tr>
          ) : (
            map(data, (social, index) => (
              <tr key={`${social?.value}-${index}`}>
                <td
                  style={{
                    paddingBottom: '3px',
                    ...(!social?.value && { display: 'none' }),
                  }}
                >
                  <a href={handleUrlRedirection(social?.value)}>
                    <SocialLinkTypeWrapper
                      signature={signature}
                      filename={social?.name}
                      type={type}
                      styles={{
                        height: size,
                        width: size,
                        backgroundColor: design?.primaryBrandColor,
                        fontSize: size - 4,
                        display: 'block',
                      }}
                    >
                      {['letter', 'word'].includes(type) ? null : (
                        <img
                          src={
                            socialImages?.[
                              SOCIAL_LINKS.find((s) => s.name === social?.name)
                                .key
                            ]
                          }
                          style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                          }}
                          alt={`${social?.name}-icon`}
                        />
                      )}
                    </SocialLinkTypeWrapper>
                  </a>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </td>
  );
}

export default SocialLinks;
