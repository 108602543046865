/* eslint-disable arrow-body-style */
import { Button } from 'components/ui/button';
import { useRouter } from 'hooks/router';
import { useTheme } from 'hooks/theme';
import { useWorkspaceId } from 'hooks/workspace';
import { ArrowLeft } from 'lucide-react';
import React from 'react';

const WorkspaceTemplateSidebar = ({ activeTab, setActiveTab, items }) => {
  const { color, foregroundColor } = useTheme();




  return (
    <div>

      <div
      className='grid grid-cols-3 w-full h-[70px] overflow-hidden'
        style={{
          // width: 100,
          backgroundColor: '#fff',
          borderRight: '1px solid #f0f0f0',
        }}
      >
        {items.map(({ icon: Icon, label, key }) => {
          const onClick = () => {
            setActiveTab(key);
          };

          const isActive = activeTab === key;

          return (
            <div
              style={{
                padding: '10px',
                cursor: 'pointer',
                ...(isActive
                  ? {
                      backgroundColor: foregroundColor,
                      borderBottom: `4px solid ${color}`,
                    }
                  : {}),
              }}
              key={key}
              onClick={onClick}
            >
              <div>
                <center>
                  <Icon fill={isActive ? color : '#7a7289'} />
                </center>
                <center>
                  <div style={{ color: isActive ? color : '#7a7289' }}>
                    {label}
                  </div>
                </center>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkspaceTemplateSidebar;
