import { useAppContext } from 'AppContext';
import { useGetRoutes } from 'helpers/useGetRoutes';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { publicRoutes } from 'routes';

const PublicLayout = () => {
  const { getRoutes } = useGetRoutes();
  const { getToken } = useAppContext();
  const idToken = getToken();
  const redirect = idToken ? '/app' : '/auth/login';
  return (
    <Routes>
      {getRoutes(publicRoutes)}
      <Route path="*" element={<Navigate to={redirect} />} />
    </Routes>
  );
};

export default PublicLayout;
