/* eslint-env browser */
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { hardReaload, isMatchingURL } from 'common/utils';
import { WorkspaceContextProvider } from 'context/WorkspaceContext';
import { toast } from 'hooks/use-toast';
import { PostHogProvider } from 'posthog-js/react';
import SubscriptionModal from 'components/SubscriptionModal';
import { BrandContextProvider } from 'context/BrandContext';
import client from './apollo';
import { AppContextProvider } from './AppContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import { antdConfigTheam } from './common/constants';
import { ToastAction } from './components/ui/toast';
import { Toaster } from './components/ui/toaster';

const options = {
  api_host: process.env.REACT_APP_POST_HOG_API_HOST,
};

const root = createRoot(document?.getElementById('root'));

const renderApp = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <BrandContextProvider>
          <WorkspaceContextProvider>
            <AppContextProvider>
              <ConfigProvider theme={antdConfigTheam}>
                <Toaster />
                <div>
                  <App />
                </div>
              </ConfigProvider>
            </AppContextProvider>
          </WorkspaceContextProvider>
        </BrandContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

const withPostHogProvider = (children) => (
  <PostHogProvider
    apiKey={process.env.REACT_APP_POST_HOG_API_KEY}
    options={options}
  >
    {children}
  </PostHogProvider>
);

root?.render(isMatchingURL() ? withPostHogProvider(renderApp()) : renderApp());

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration?.waiting) {
      // eslint-disable-next-line no-console
      console?.log('Inside registration');
      toast({
        description:
          'New version available! Click Reload to get the latest version.',
        closeicn: 'success',
        action: (
          <ToastAction
            altText="Try again"
            Action={() => {
              hardReaload();
            }}
          >
            Reload
          </ToastAction>
        ),
        duration: 10,
        onClose: () => {
          hardReaload();
        },
      });
    }
  },
});

reportWebVitals();
