import React from 'react';
import { get } from 'lodash';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { getFieldData, getLiveUrl } from 'common/utils';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtySeven({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const headshotSection =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');
  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  const contentWidth = get(design, 'styles.content.style.width', 600);

  return (
    <>
      <table
        width={`${contentWidth}px`}
        style={{
          margin: '0 !important',
          padding: '0 !important',
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize: 'inherit',
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              role="presentation"
              style={{}}
            >
              <tr>
                <table
                  cellPadding="0px"
                  cellSpacing="0"
                  border="0"
                  ref={mainContentRef}
                >
                  <tr>
                    {getFieldData(fields, 'headshotUrl') && (
                      <td
                        style={{
                          paddingRight: '15px',
                          paddingBottom: '8px',
                        }}
                      >
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          {getFieldData(fields, 'headshotUrl') && (
                            <tr align="center">
                              {HeadshotImage({
                                data: getFieldData(fields, 'headshotUrl'),
                                design,
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    )}
                    {!allUrlsAreNull && (
                      <td>
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          {(getFieldData(fields, 'name') ||
                            getFieldData(fields, 'department')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                    paddingBottom: '2px',
                                  }}
                                >
                                  <tr>
                                    {getFieldData(fields, 'name') &&
                                      Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontFamily: 'inherit',
                                          paddingRight: '8px',
                                          paddingBottom: '5px',
                                        },
                                      })}
                                    {getFieldData(fields, 'department') && (
                                      <td
                                        style={{
                                          verticalAlign: 'top',
                                          color: getFieldData(
                                            fields,
                                            'department',
                                          )
                                            ? signature?.design
                                                ?.primaryBrandColor
                                            : 'none',
                                        }}
                                      >
                                        {' '}
                                        |&nbsp;
                                      </td>
                                    )}

                                    {getFieldData(fields, 'department') &&
                                      Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}

                          {!allUrlsAreNull && (
                            <tr>
                              <td
                                height={5}
                                className={TEXT_IDS.SEPARATOR}
                                colSpan={2}
                                style={{
                                  borderTop: !allUrlsAreNull
                                    ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                                    : '',
                                  lineHeight: '0',
                                }}
                              />
                            </tr>
                          )}

                          {(getFieldData(fields, 'position') ||
                            getFieldData(fields, 'companyName')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                  }}
                                >
                                  {getFieldData(fields, 'position') &&
                                    Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                        paddingBottom: '10px',
                                      },
                                    })}
                                  {getFieldData(fields, 'companyName') && (
                                    <td
                                      style={{
                                        verticalAlign: 'top',
                                        color: getFieldData(
                                          fields,
                                          'companyName',
                                        )
                                          ? signature?.design?.primaryBrandColor
                                          : 'none',
                                      }}
                                    >
                                      {' '}
                                      |&nbsp;
                                    </td>
                                  )}
                                  {getFieldData(fields, 'companyName') &&
                                    CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}
                                </table>
                              </td>
                            </tr>
                          )}
                          {(getFieldData(fields, 'phone') ||
                            getFieldData(fields, 'email')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                  }}
                                >
                                  {getFieldData(fields, 'email') &&
                                    Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}
                                  {getFieldData(fields, 'phone') && (
                                    <td
                                      style={{
                                        verticalAlign: 'top',
                                        color: getFieldData(fields, 'phone')
                                          ? signature?.design?.primaryBrandColor
                                          : 'none',
                                      }}
                                    >
                                      {' '}
                                      |&nbsp;
                                    </td>
                                  )}
                                  {getFieldData(fields, 'phone') &&
                                    Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {},
                                    })}
                                </table>
                              </td>
                            </tr>
                          )}
                          {(getFieldData(fields, 'website') ||
                            getFieldData(fields, 'companyAddress')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                  }}
                                >
                                  {getFieldData(fields, 'website') &&
                                    Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}
                                  {getFieldData(fields, 'companyAddress') && (
                                    <td
                                      style={{
                                        verticalAlign: 'top',
                                        color: getFieldData(
                                          fields,
                                          'companyAddress',
                                        )
                                          ? signature?.design?.primaryBrandColor
                                          : 'none',
                                      }}
                                    >
                                      {' '}
                                      |&nbsp;
                                    </td>
                                  )}
                                  {getFieldData(fields, 'companyAddress') &&
                                    CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                    })}
                                </table>
                              </td>
                            </tr>
                          )}

                          {(getFieldData(fields, 'companyPhone') ||
                            getFieldData(fields, 'companyEmail')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                  }}
                                >
                                  {getFieldData(fields, 'companyEmail') &&
                                    CompanyEmail({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyEmail',
                                      ),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}
                                  {getFieldData(fields, 'companyPhone') && (
                                    <td
                                      style={{
                                        verticalAlign: 'top',
                                        color: getFieldData(
                                          fields,
                                          'companyPhone',
                                        )
                                          ? signature?.design?.primaryBrandColor
                                          : 'none',
                                      }}
                                    >
                                      {' '}
                                      |&nbsp;
                                    </td>
                                  )}
                                  {getFieldData(fields, 'companyPhone') &&
                                    CompanyPhone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyPhone',
                                      ),
                                      style: {},
                                    })}
                                </table>
                              </td>
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              <td style={{ paddingBottom: '2px' }}>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  {!!socialFields?.length && (
                                    <tr>
                                      {SocialLinks({
                                        signature,
                                        showAwsIcons,
                                        data: socialFields,
                                        design,
                                        style: { paddingTop: '8px' },
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                    )}
                  </tr>
                </table>
              </tr>
              {getFieldData(fields, 'companyLogo') && (
                <tr align="left">
                  {CompanyLogo({
                    data: getFieldData(fields, 'companyLogo'),
                    design,
                  })}
                </tr>
              )}
            </table>
          </td>
        </tr>
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleThirtySeven;
