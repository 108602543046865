/* eslint-disable no-console */
/* eslint-disable no-undef */
import { ChevronRight } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { Separator } from 'components/ui/separator';
import { toast } from 'hooks/use-toast';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'hooks/router';
import { SIGNUP, LOGIN } from 'modules/Auth/graphql/Mutations';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInWithGooglePopup } from 'modules/Auth/firebase';
import { Input } from 'components/ui/input';
import { GoogleIcon } from 'assets/svg';
import { Button } from 'components/ui/button';
import { useAppContext } from 'AppContext';
import { useHeadshotUpload } from 'hooks/headshot';
import { get } from 'lodash';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import {
  DEFAULT_DESIGN_STYLE,
  DISPLAY_MODE,
  EMAIL_PLACEHOLDER,
  FONT_FAMILY,
  onboardingStatus,
  PRIMARY_COLOR,
  TOKEN,
} from 'common/constants';
import { signatureFieldUpdate } from 'lib/utils';
import { SIGNATURE_FIELDS } from 'modules/Onboarding/constants';
import { useGetTemplates, useTemplateData } from 'hooks/template';

const formSchema = z.object({
  email: z
    .string()
    .email({ message: 'Please enter a valid email address.' })
    .nonempty({ message: 'Email is required.' }),
  password: z.string().min(1, 'Password is required'),
});

const LoginModal = ({ open = false, setOpen }) => {
  const [getTemplates] = useTemplateData();
  const {
    state: { headshot },
    initializeAuth,
    setIsLogin,
  } = useAppContext();
  const [headshotUrl, setHeadshotUrl] = useState('');
  const { handleGlobalHeadshotUpload } = useHeadshotUpload();
  const { data } = useGetTemplates();
  const { templateId, template } = data[0];
  const [signupMutate, { loading: signupLoading }] = useMutation(SIGNUP);
  const [userLogin, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  const [isSignup, setIsSignup] = useState(true);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '', password: '' },
  });

  const closeModal = () => {
    setOpen(false);
  };

  const handleModeSwitch = () => {
    setIsSignup(!isSignup); // Toggle between Sign Up and Login modes
  };

  useEffect(() => {
    getTemplates({
      variables: {
        pagination: {
          skip: 0, // Start from the beginning
          limit: 'max', // Default to fetching all items
        },
      },
    });
  }, []);

  const redirectToHeadshot = async (token) => {
    localStorage.setItem(TOKEN, token);

    const updatedHeadshot = await handleGlobalHeadshotUpload(
      headshot,
      `${headshot?.style?.key}`,
    );

    const previousDownloadUrl = updatedHeadshot?.config?.output
      ? `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${updatedHeadshot?.config?.output}`
      : '';
    localStorage.setItem('headshotUrl', previousDownloadUrl);
    setHeadshotUrl(previousDownloadUrl);
  };

  const successCallback = async (
    accessToken,
    userData,
    refreshToken,
    redirectSlug,
  ) => {
    initializeAuth(accessToken, userData, refreshToken, { redirectSlug });
    redirectToHeadshot(accessToken);
  };

  function successCallbackLogin(
    accessToken,
    userData,
    refreshToken,
    redirectSlug,
  ) {
    initializeAuth(accessToken, userData, refreshToken, { redirectSlug });
    localStorage.setItem(TOKEN, accessToken);
    redirectToHeadshot(accessToken);
    setIsLogin(true);
  }

  const updatedFields = signatureFieldUpdate(SIGNATURE_FIELDS, [
    {
      name: 'headshotUrl',
      value: localStorage.getItem('headshotUrl') || headshotUrl || '',
    },
  ]);

  const createSignature = async (token) => {
    const introspectSecret = process.env.REACT_APP_INTROSPECTION_SECRET;
    const userAuth = token ? `Bearer ${token}` : '';
    const endpoint = process.env.REACT_APP_SERVER_URL;
    try {
      // Step 1: Fetch Workspaces
      const workspacesResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userAuth,
          'x-introspection-restriction-secret': introspectSecret,
        },
        body: JSON.stringify({
          query: `
            query GetWorkspaces {
              getWorkspaces {
                data {
                  id
                  name
                  signatureLimit
                  signatureUsed
                  workspaceDomain
                  utagEnabled
                }
              }
            }
          `,
        }),
      });

      const workspacesResult = await workspacesResponse.json();

      // Check for errors in the response
      if (workspacesResult.errors) {
        console.error(
          'GraphQL Error (GetWorkspaces):',
          workspacesResult.errors,
        );
      }

      const [workspace] = workspacesResult?.data?.getWorkspaces?.data || [];
      const workspaceId = workspace?.id;

      if (!workspaceId) {
        throw new Error('Workspace ID not found');
      }

      // Step 2: Upsert Signature Mutation
      const data = {
        design: {
          theme: DISPLAY_MODE?.LIGHT,
          font: FONT_FAMILY,
          styles: DEFAULT_DESIGN_STYLE,
          primaryBrandColor: PRIMARY_COLOR,
          icons: [],
        },
        fields: updatedFields,
        templateId,
        template,
        groupId: null,
      };

      const upsertSignatureResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userAuth,
          'x-introspection-restriction-secret': introspectSecret,
        },
        body: JSON.stringify({
          query: `
            mutation UpsertSignature($data: CreateSignatureInput!, $where: SignatureUniqueWhereInput) {
              upsertSignature(data: $data, where: $where) {
                id
                workspaceId
                design {
                  theme
                  font
                  styles
                  icons
                }
                templateId
                name
              }
            }
          `,
          variables: {
            data,
            where: { workspaceId },
          },
        }),
      });

      const upsertResult = await upsertSignatureResponse.json();

      // Check for errors in the response
      if (upsertResult.errors) {
        console.error('GraphQL Error (UpsertSignature):', upsertResult.errors);
      }

      const signatureId = upsertResult?.data?.upsertSignature?.id;

      if (!signatureId) {
        throw new Error('Signature ID not found');
      }

      // Return the redirect URL
      return {
        redirectSlug: `/app/${workspaceId}/signature/${signatureId}/headshot`,
      };
    } catch (error) {
      console.error('Error in createSignatureWithFetch:', error);
      throw error;
    }
  };

  const [socialLogin, { loading: socialLoading }] = useMutation(LOGIN, {
    onCompleted: async ({ loginUser }) => {
      const { accessToken, data, refreshToken, redirectSlug } = loginUser;
      const userData = data;

      if (userData?.onboardingStatus === 'COMPLETED') {
        const tokenRefresh = refreshToken;
        localStorage.setItem(TOKEN, accessToken);
        initializeAuth(accessToken, userData, tokenRefresh, {
          isSignUp: true,
          redirectSlug,
        });
        setIsLogin(true);
      } else {
        const { redirectSlug } = await createSignature(accessToken);
        await successCallback(accessToken, data, refreshToken, redirectSlug);
      }
    },
    onError: (error) => {
      // Handle error here if needed
      console.error('Login error:', error);
    },
  });

  const successCallbackSocialAuth = async (accessToken) => {
    await socialLogin({ variables: { data: { token: accessToken } } });
  };
  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
        onboardingStatus: onboardingStatus.COMPLETED,
      };
      // const response = await signupMutate({
      //   variables: { data: formValues },
      // });
      const response = isSignup
        ? await signupMutate({ variables: { data: formValues } })
        : await userLogin({ variables: { data: formValues } });

      if (isSignup) {
        const { accessToken, data, refreshToken } =
          response?.data?.signUp || response?.data?.loginUser || {};
        const { redirectSlug } = await createSignature(accessToken);

        if (response?.data?.signUp || response?.data?.loginUser) {
          await successCallback(accessToken, data, refreshToken, redirectSlug);
        } else {
          form.reset(values);
        }
      } else {
        const { accessToken, data, refreshToken, redirectSlug } =
          response?.data?.loginUser || {};

        if (response?.data) {
          const userData = data;
          const tokenRefresh = refreshToken;

          if (successCallback) {
            successCallbackLogin(
              accessToken,
              userData,
              tokenRefresh,
              redirectSlug,
            );
          }
        } else {
          form?.reset(values);
        }
      }
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: error?.message || 'Something went wrong',
      });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithGooglePopup();
      if (user?.accessToken) {
        const { accessToken } = user;
        await successCallbackSocialAuth(accessToken);
      }
    } catch (error) {
      // Handle sign-in error
    }
  };
  return (
    <Dialog open={open} onOpenChange={closeModal}>
      <DialogContent
        hideCloseButton
        className="h-[500px] sm:max-w-md max-w-lg sm:p-6"
      >
        <header>
          <div className="text-medium-l font-primary">
            {' '}
            {isSignup ? 'Sign up with email' : 'Login with email'}
          </div>
        </header>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onFinish)}
            className="space-y-[27px] mt-[8px]"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="text-darkblue font-medium text-[12px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                      placeholder={EMAIL_PLACEHOLDER}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="text-darkblue font-medium text-[12px]">
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                      placeholder="Minimum 8 characters"
                      maxLength={16}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="h-[40px] w-full mt-2"
              loading={signupLoading || loginLoading || socialLoading}
            >
              <div className="flex items-center space-x-1">
                <span>{isSignup ? 'Sign Up' : 'Login'}</span>
                <ChevronRight width="20" height="20" />
              </div>
            </Button>
            <p className=" text-secondary-400 text-medium-base font-primary  font-[500]  flex justify-center">
              {isSignup ? 'Already have an account?' : "Don't have an account?"}
              <div
                className="text-secondary-400 pl-1 cursor-pointer"
                onClick={handleModeSwitch}
              >
                <u>{isSignup ? 'Login' : 'Sign Up'}</u>
              </div>
            </p>
          </form>
        </Form>

        <div className="flex flex-col gap-y-[16px]">
          <div className="flex items-center my-4 w-full">
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
            <div className="text-secondary-400 mx-2 text-center text-xs font-semibold leading-[24px] font-primary whitespace-nowrap">
              OR
            </div>
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
          </div>
          <Button
            onClick={handleGoogleSignIn}
            variant="outline"
            className="h-[40px]"
            disabled={signupLoading || loginLoading || socialLoading}
            size="sm"
          >
            <div className="flex justify-center items-center">
              <GoogleIcon />
              <span className="ml-2">Continue with Google</span>
            </div>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
