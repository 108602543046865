import React from 'react';
import { useTableWidth } from 'hooks/table';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import HeadshotImage from './components/HeadshotImage';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import SocialLinks from './components/SocialLinks';
import CompanyAddress from './components/CompanyAddress';
import Website from './components/Website';
import Email from './components/Email';
import Phone from './components/Phone';
import CompanyName from './components/CompanyName';
import Department from './components/Department';
import Name from './components/Name';
import Position from './components/Position';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleNineteen({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field?.name),
  );

  const contentWidth = get(design, 'styles.content.style.width', 600);
  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td aria-label="main">
            <table
              ref={mainContentRef}
              cellPadding="0"
              cellSpacing="0"
              border="0"
              role="presentation"
              style={{
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td
                  style={{
                    fontSize: 'inherit',
                    paddingBottom: '8px',
                  }}
                >
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                  >
                    <tr>
                      {/* {!!socialFields?.length && (
      <td>
        <table
          cellPadding="0"
          cellSpacing="0"
          border="0"
          style={{ paddingRight: '14px' }}
        >
          <tr>
            {SocialLinks({
              signature,
              showAwsIcons,
              alignment: 'vertical',
              data: socialFields,
              design,
            })}
          </tr>
        </table>
      </td>
    )} */}
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{ paddingRight: '14px' }}
                        >
                          {(getFieldData(fields, 'name') ||
                            getFieldData(fields, 'position') ||
                            getFieldData(fields, 'department') ||
                            getFieldData(fields, 'companyName')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {getFieldData(fields, 'name') && (
                                    <tr>
                                      {Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 700,
                                          color: design?.primaryBrandColor,
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'position') && (
                                    <tr>
                                      {Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                      })}
                                    </tr>
                                  )}

                                  {(getFieldData(fields, 'department') ||
                                    getFieldData(fields, 'companyName')) && (
                                    <tr>
                                      <td>
                                        <table
                                          cellPadding="0"
                                          cellSpacing="0"
                                          border="0"
                                          role="presentation"
                                          style={{
                                            borderCollapse:
                                              'collapse !important',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          <tr>
                                            {getFieldData(
                                              fields,
                                              'department',
                                            ) &&
                                              Department({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'department',
                                                ),
                                                style: {
                                                  paddingRight: '8px',
                                                },
                                              })}

                                            {getFieldData(
                                              fields,
                                              'companyName',
                                            ) &&
                                              CompanyName({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'companyName',
                                                ),
                                              })}
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  )}

                                  {!!socialFields?.length && (
                                    <tr>
                                      <td>
                                        <table cellPadding="0">
                                          <tr>
                                            {SocialLinks({
                                              signature,
                                              showAwsIcons,
                                              data: socialFields,
                                              design,
                                            })}
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}

                          {(getFieldData(fields, 'phone') ||
                            getFieldData(fields, 'email') ||
                            getFieldData(fields, 'website') ||
                            getFieldData(fields, 'companyAddress')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                  role="presentation"
                                >
                                  {getFieldData(fields, 'phone') && (
                                    <tr>
                                      {Phone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'phone'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyPhone') && (
                                    <tr>
                                      {CompanyPhone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyPhone',
                                        ),
                                        style: { paddingRight: '8px' },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'email') && (
                                    <tr>
                                      {Email({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'email'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyEmail') && (
                                    <tr>
                                      {CompanyEmail({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyEmail',
                                        ),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'website') && (
                                    <tr>
                                      {Website({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'website'),
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'companyAddress') && (
                                    <tr>
                                      {CompanyAddress({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyAddress',
                                        ),
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                      <td>
                        {(getFieldData(fields, 'headshotUrl') ||
                          getFieldData(fields, 'companyLogo')) && (
                          <table cellPadding="0" cellSpacing="0" border="0">
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyLogo') && (
                              <tr align="center">
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                })}
                              </tr>
                            )}
                          </table>
                        )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        {/* {getFieldData(fields, 'ctaUrl') && getFieldData(fields, 'ctaTitle') && (
      <tr>
        {CallToAction({
          signature,
          showAwsIcons,
          design,
          data: {
            ctaUrl: getFieldData(fields, 'ctaUrl'),
            ctaTitle: getFieldData(fields, 'ctaTitle'),
          },
        })}
      </tr>
    )}
    {getFieldData(fields, 'ctaBannerImg') &&
      getFieldData(fields, 'ctaBannerUrl') && (
        <tr>
          {CallToActionBanner({
            data: {
              ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
              ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
            },
            design,
            style: { textAlign: 'left' },
          })}
        </tr>
      )}

    {!!getFieldData(fields, 'socialBanners')?.value?.length && (
      <tr>
        {SocialBanners({
          data: getFieldData(fields, 'socialBanners'),
          design,
          style: {
            textAlign: 'left',
          },
        })}
      </tr>
    )}
    {getFieldData(fields, 'footer') && (
      <tr>
        {Footer({
          data: getFieldData(fields, 'footer'),
          design,
          style: { colSpan: '3' },
        })}
      </tr>
    )} */}

        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleNineteen;
