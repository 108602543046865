/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout';
import MaintenancePage from 'components/MaintenancePage';
import MetaTags from 'components/MetaTags';
import SentryErrorBoundary from 'components/SentryErrorBoundary';
import { getBaseURL, hardReaload, isMatchingURL } from 'common/utils';
import { useGetBrandByUrl, useFeatureChecks } from 'hooks/brand';
import LoaderComponent from 'components/LoaderComponent';
import ReactGA from 'react-ga4';
import { useRouter } from 'hooks/router';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import AuthLayout from './layouts/AuthLayout';
import PublicLayout from './layouts/PublicLayout';

const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
const baseUrl = getBaseURL();

const App = () => {
  const [getBrandByUrl] = useGetBrandByUrl();
  const [loading, setLoading] = useState(true);
  const { isPublicHeadshotAllowed } = useFeatureChecks();

  const googleAnalyticsCode = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;
  useEffect(() => {
    if (googleAnalyticsCode) {
      ReactGA.initialize(googleAnalyticsCode);
    }
    hardReaload(false);
  }, []);

  useEffect(() => {
    if (googleAnalyticsCode) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [window.location.pathname]);

  const fetchBrandByUrl = (url) => {
    getBrandByUrl({ variables: { where: { url } } }).finally(() => {
      setLoading(false); // Hide the loading indicator once data fetching is done
    });
  };

  useEffect(() => {
    if (baseUrl) {
      const url = baseUrl.includes('localhost')
        ? 'https://app.dev.syncsignature.com'
        : baseUrl;
      fetchBrandByUrl(baseUrl);
    } else {
      setLoading(true); // Hide loading indicator if no base URL is provided
    }
  }, [baseUrl]);

  let content;

  if (loading) {
    content = <LoaderComponent />;
  } else if (maintenance === 'true') {
    content = <MaintenancePage />;
  } else {
    content = (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/app/*" element={<AppLayout />} />
        {isPublicHeadshotAllowed && (
          <Route path="/*" element={<PublicLayout />} />
        )}
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    );
  }


  return (
    <SentryErrorBoundary>
      <MetaTags
        title="Sync Signature"
        description="Discover Sync Signature's seamless solutions."
        imageUrl="https://app.dev.syncsignature.com/ogai.png"
        url="https://app.dev.syncsignature.com"
      />
      {isMatchingURL() && <TawkMessengerReact propertyId="675c0707af5bfec1dbdb537c" widgetId="1ievnmu59" onLoad={() => {console.log('onload successful')}} />}
      {content}
    </SentryErrorBoundary>
  );
};

export default App;
