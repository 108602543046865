import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormMessage,
} from 'components/ui/form'; // shadcn form components
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from 'components/ui/select'; // shadcn select components
import { useAppContext } from 'AppContext';
import OnboardingSignaturePreview from 'components/OnboardingSignaturePreview';
import SideCoverHeadshot from 'assets/images/side-cover.png';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { useGetSignature } from 'hooks/signature';
import { useRouter } from 'hooks/router';
import { useWorkspaceId } from 'hooks/workspace';
import { useRedirect } from 'hooks/auth';
import LoaderComponent from 'components/LoaderComponent';
import { ToggleGroup, ToggleGroupItem } from 'components/ui/toggle-group';
import {
  AppleMail,
  GlobeIconNew,
  Gmail,
  GoogleIcon,
  Microsoft365Icon,
  Outlook,
  ThunderbirdLogoIcon,
  Yahoo,
} from 'assets/svg';
import { useUpdateUser } from 'hooks/user';
import { onboardingStatus } from 'common/constants';
import { useGetBrandDetails } from 'hooks/brand';
import { ChevronRight } from 'lucide-react';

const OnboardingInfo = () => {
  const {
    state: { signature, currentUser },
  } = useAppContext();
  const { logoUrl } = useGetBrandDetails();

  const { params, navigate } = useRouter();
  const { id: signatureId } = params;
  const { workspaceId } = useWorkspaceId();

  const [getSignature, { signatureLoading }] = useGetSignature();
  const [updateUser] = useUpdateUser();
  const { redirectUser } = useRedirect();

  const [loading, setLoading] = useState(signatureLoading);

  const form = useForm({
    defaultValues: {
      usage: 'myself',
      teamSize: '',
      emailClient: [''],
    },
  });

  const { control, handleSubmit, watch } = form;

  const loadSignatureData = async () => {
    setLoading(true);
    await getSignature({
      variables: {
        where: { signatureId, workspaceId },
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (signatureId) {
      if (
        signatureId === 'undefined' ||
        signatureId === 'null' ||
        signatureId === null
      ) {
        redirectUser();
      }
      loadSignatureData();
    }
  }, [signatureId]);

  const usage = watch('usage');

  const onSubmit = async (data) => {
    setLoading(true);
    await updateUser({
      variables: {
        data: {
          onboardingStatus: onboardingStatus?.COMPLETED,
          usedFor: usage,
          companySize: data.teamSize || null,
          emailClient: data.emailClient.join(' '),
        },
        where: {
          id: currentUser?.id,
        },
      },
    });
    setLoading(false);
    navigate(
      `/app/${workspaceId}/signature/${signatureId}/information?showTemplate=true&&isCreate=false?primaryColorPersists=true`,
    );
  };

  if (loading) {
    return <LoaderComponent />;
  }

  const emailClients = [
    { value: 'gmail', label: 'Gmail', Icon: Gmail },
    { value: 'outlook', label: 'Outlook', Icon: Outlook },
    { value: 'appleMail', label: 'Apple Mail', Icon: AppleMail },
    { value: 'yahoo', label: 'Yahoo', Icon: Yahoo },
    { value: 'thunderbird', label: 'Thunderbird', Icon: ThunderbirdLogoIcon },
    { value: 'googleWorkspace', label: 'Google Workspace', Icon: GoogleIcon },
    {
      value: 'office365',
      label: 'Microsoft Office 365',
      Icon: Microsoft365Icon,
    },
    { value: 'other', label: 'Other', Icon: GlobeIconNew },
  ];

  return (
    <div className="min-h-screen h-full max-h-screen grid grid-cols-2 lg:grid-cols-1 overflow-auto ">
      <div className="flex flex-col justify-center bg-primary-foreground p-[16px]  overflow-auto">
        <div className="w-full  max-w-[600px] mx-auto">
          <img
            src={logoUrl || '/logo.svg'}
            height={40}
            width="auto"
            alt="logo"
          />
          <h1
            level={1}
            className="text-primary font-primary my-6 text-[32px] font-semibold leading-[36px]"
          >
            Few more details
          </h1>

          <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-5">
                <FormField
                  control={control}
                  name="usage"
                  render={({ field }) => (
                    <FormItem>
                      <Label className="block text-sm font-medium text-gray-700">
                        How do you plan on using SyncSignature?
                      </Label>
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-[300px]">
                            <SelectValue placeholder="Select usage" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="myself">Myself</SelectItem>
                            <SelectItem value="company">My Company</SelectItem>
                            <SelectItem value="agency">Agency</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {usage === 'company' && (
                  <FormField
                    control={control}
                    name="teamSize"
                    render={({ field }) => (
                      <FormItem>
                        <Label className="block text-sm font-medium text-gray-700">
                          How big is your team?
                        </Label>
                        <FormControl>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="w-[300px]">
                              <SelectValue placeholder="Select team size" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="1-5">1-5</SelectItem>
                              <SelectItem value="6-10">6-10</SelectItem>
                              <SelectItem value="11-25">11-25</SelectItem>
                              <SelectItem value="26-50">26-50</SelectItem>
                              <SelectItem value="51-100">51-100</SelectItem>
                              <SelectItem value="100+">100+</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={control}
                  name="emailClient"
                  render={({ field }) => (
                    <FormItem>
                      <Label className="text-sm font-medium text-gray-700">
                        Select your email client:
                      </Label>
                      <FormControl>
                        <ToggleGroup
                          type="multiple"
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className="flex justify-start flex-wrap gap-2 w-[500px]"
                        >
                          {emailClients.map(({ value, label, Icon }) => (
                            <ToggleGroupItem
                              key={value}
                              value={value}
                              className="flex items-center bg-white-0 gap-2 p-4 border-1 border-solid border-white-0 rounded-full cursor-pointer transition-colors duration-200 data-[state=on]:border-primary data-[state=on]:border-1 data-[state=on]:border-solid data-[state=on]:bg-transparent"
                            >
                              <div className="h-5 w-5">
                                <Icon className={`h-full w-full `} />
                              </div>
                              {label}
                            </ToggleGroupItem>
                          ))}
                        </ToggleGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button type="submit" className="h-[40px] w-full mt-10">
                <div className="flex items-center space-x-1">
                  <span>Submit</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
            </form>
          </Form>
        </div>
      </div>
      <div
        className="flex items-center justify-center lg:hidden h-full min-h-screen gap-[10px] shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center md:w-0 md:h-full"
        style={{ backgroundImage: `url(${SideCoverHeadshot})` }}
      >
        {loading ? (
          <LoaderComponent />
        ) : (
          <OnboardingSignaturePreview signature={signature} />
        )}
      </div>
    </div>
  );
};

export default OnboardingInfo;
