import React, { forwardRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const CustomEditor = forwardRef(({ value, onBlur, onChange }, ref) => {
  const config = useMemo(
    () => ({
      toolbar: true,
      toolbarAdaptive: false,
      readonly: false,
      showPlaceholder: true,
      buttons: ['bold', 'italic', 'underline', 'link', 'fontsize', 'brush'],
      statusbar: false,
      disablePlugins: 'add-new-line',
      askBeforePasteHTML: false, // Disable "Paste as HTML" prompt
      askBeforePasteFromWord: false, // Disable "Paste from Word" prompt
      defaultActionOnPaste: 'insert_only_text', // Insert only text by default on paste
      minHeight: '150px', // Minimum height
      height: 'auto',
      style: {},
    }),
    [],
  );

  return (
    <JoditEditor
      ref={ref}
      value={value}
      config={config}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
});

export default CustomEditor;
