/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { GoogleIcon } from 'assets/svg';
import { encryptToken } from 'common/utils';
import { Button } from 'components/ui/button';
import useGoogleWorkspaceUsers from 'hooks/google';
import { useSetGoogleAuthToken } from 'hooks/google-marketplace';
import { useRouter } from 'hooks/router';
import { v4 as uuid } from 'uuid';

const GoogleAuth = () => {
  const { handleFetchGoogleWorkspaceUsers } = useGoogleWorkspaceUsers();
  const [setGoogleAuthToken, { loading, error }] = useSetGoogleAuthToken();
  const {
    location: { search },
  } = useRouter();

  const params = new URLSearchParams(search);
  const lastVisitedUrl = decodeURIComponent(params.get('lastVisitedUrl') || '');

  const handleGoogleAuth = async () => {
    try {
      const token = await handleFetchGoogleWorkspaceUsers();
      if (token) {
        const encryptedToken = await encryptToken(token);
        const id = uuid(); // Generate a unique identifier

        const res = await setGoogleAuthToken({
          variables: {
            where: {
              uuid: id,
              accessToken: encryptedToken,
            },
          },
        });

        window.location.href = res
          ? `${lastVisitedUrl}?uuid=${id}`
          : `${lastVisitedUrl}`;
      }
    } catch (err) {
      console.error('Error during Google Auth:', err.message);
    }
  };

  useEffect(() => {
    handleGoogleAuth();
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-foreground">
      <div className="bg-white-0 shadow-lg rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold text-center mb-4">Sync with Google</h2>
        <p className="text-center text-gray-600 mb-6">
          Connect your Google Workspace account to continue.
        </p>
        <Button
          onClick={handleGoogleAuth}
          className="h-[40px] w-full font-primary text-button-s bg-white-0 flex items-center justify-center"
          variant="outline"
          disabled={loading}
        >
          <div className="flex justify-center items-center">
            <GoogleIcon />
            <span className="ml-2">
              {loading ? 'Syncing with Google...' : 'Sync with Google'}
            </span>
          </div>
        </Button>
        {error && (
          <div className="text-red-500 mt-2 text-center">
            Error: {error.message || 'Failed to sync with Google'}
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleAuth;
