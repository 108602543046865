import { getTemplateImages } from 'common/constants';

const Template33 = [
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a2',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_33'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Anjali Kumar',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Fashionista',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '23st, Boston, USA',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'companylogo1',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: null,
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 's@greentech.com',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'anjali@gmail.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(345) 087 - 1239',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'www.anjalikumar.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: 'Enjoy Flat 50% off',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://yourwebsite.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#2B554E',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 200,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.5,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'none',
        },
        socialIcon: {
          style: {
            size: 16,
            color: '#2B554E',
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 118,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            color: '#2B554E',
            width: 1,
          },
        },
        footer: {
          style: {
            width: 500,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#56B7FF',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33',
  },
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_33_1'),
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'RIMA BOSTON',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: 'Student, Pursuing PHD',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '23st, Boston, USA',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'companylogo1',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 's@greentech.com',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'rima@adventuni.org',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://rimaboston.me',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: 'My Research findings',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://newcta.com',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaBannerImg',
        type: 'company',
        label: 'CTA Banner',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'socialBanners',
        type: 'company',
        label: 'Social Banners',
        value: [],
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyLogoLink',
        type: 'company',
        label: 'Company Logo Link',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyPhone',
        type: 'company',
        label: 'Company Phone',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyEmail',
        type: 'company',
        label: 'Company Email',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'signOff',
        type: 'company',
        label: 'SignOff',
        value: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'customFields',
        type: 'company',
        label: 'Custom Fields',
        value: [],
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
    ],
    design: {
      primaryBrandColor: '#7f56d9',
      font: 'Georgia, serif',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'bordered',
            width: 278,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#171717',
            fontSize: 12,
            lineHeight: 2.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'letter',
          style: {
            size: 20,
          },
        },
        footer: {
          style: {
            color: '#000000',
            width: 500,
            fontSize: 9,
          },
        },
        signOff: {
          style: {
            fontSize: 20,
          },
        },
        headshot: {
          style: {
            width: 92,
            borderRadius: 4,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        separator: {
          style: {
            type: 'solid',
            color: '#7f56d9',
            width: 1,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 16,
            color: '#7f56d9',
          },
        },
        companyLogo: {
          style: {
            width: 123,
          },
        },
        content: {
          style: {
            width: 600,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    templateId: 'eed97775-ce17-434b-a161-5e2a64922ae2',
    headshotId: null,
    template: {
      id: 'eed97775-ce17-434b-a161-5e2a64922ae2',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33-1',
  },
  {
    id: '0da41bb4-1dfa-41a5-8171-f9bf5bc680a2',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_33_2'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Lily Foster',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Beauty & Skincare Expert',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '23st, Boston, USA',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'companylogo1',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 's@greentech.com',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'lily.foster@llyf.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '+1 (555) 319-6078',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: 'Meet me on Skype',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://www.checkout.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: 'Sincerely',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#2B554E',
      font: 'Lucida Console, Monaco, monospace',
      theme: 'LIGHT',
      icons: null,
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 300,
            borderRadius: 0,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 12,
            lineHeight: 1.3,
          },
        },
        headerFont: {
          style: {
            fontSize: 16,
          },
        },
        icon: {
          type: 'none',
          style: {
            size: 20,
          },
        },
        socialIcon: {
          style: {
            size: 16,
            color: '#2B554E',
          },
        },
        companyLogo: {
          style: {
            width: 150,
          },
        },
        headshot: {
          style: {
            width: 79,
          },
        },
        separator: {
          style: {
            color: '#2B554E',
            width: 1,
          },
        },
        signOff: {
          style: {
            fontSize: 13,
          },
        },
      },
    },
    templateId: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
    headshotId: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#56B7FF',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: '95f62733-3d7f-4fc5-a2b5-d86b616d10b6',
      name: 'template 33',
      image: null,
      key: 'TEMPLATE_33',
      description: null,
      isActive: true,
      config: null,
      templateId: 33,
    },
    name: '33-2',
  },
];

export default Template33;
